export const isValidTag = (tag: string): boolean => {
  // Tag length should be between 3 and 50 characters
  if (tag?.length < 3 || tag?.length >= 50) {
    return false;
  }
  // Tag should start and end with a letter or number
  // Tag should only contain letters, numbers, spaces, underscores (_), and hyphens (-
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*[a-zA-Z0-9]$/;
  return regex.test(tag);
};
