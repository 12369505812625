const INITIAL_STATE = {
  jobApplication: null
};

const applySetJobApplication = (state, action) => ({
  ...state,
  jobApplication: action.jobApplication
});

function jobApplicationReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "JOB_APPLICATION_SET") {
    return applySetJobApplication(state, action);
  } else {
    return state;
  }
}

export default jobApplicationReducer;
