import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import additionalClientPreferencesReducer from "./additionalClientPreferencesReducer";
import calendarReducer from "./calendarReducer";
import clientDashboardReducer from "./clientDashboardReducer";
import clientDocDataReducer from "./clientDocDataReducer";
import clientLocationReducer from "./clientLocationReducer";
import clientLocationsReducer from "./clientLocationsReducer";
import clientUsersReducer from "./clientUsersReducer";
import conferenceSelectionReducer from "./conferenceSelectionReducer";
import conferencingOptionsReducer from "./conferencingOptionsReducer";
import domainDocDataReducer from "./domainDocDataReducer";
import emailTemplateReducer from "./emailTemplateReducer";
import eventsReducer from "./eventsReducer";
import interviewAttachmentsReducer from "./interviewAttachmentsReducer";
import interviewEmailsReducer from "./interviewEmailsReducer";
import interviewersReducer from "./interviewersReducer";
import interviewNotificationsReducer from "./interviewNotificationsReducer";
import interviewOptionsReducer from "./interviewOptionsReducer";
import interviewReducer from "./interviewReducer";
import interviewStartDateReducer from "./interviewStartDateReducer";
import jobApplicationReducer from "./jobApplicationReducer";
import miscellaneousSettingsReducer from "./miscellaneousSettingsReducer";
import sessionReducer from "./sessionReducer";
import alertPopupReducer from "./slices/alertPopup";
import automationReducer from "./slices/automation";
import domainsReducer from "./slices/domains";
import schedulingReducer from "./slices/scheduling";
import timeZoneReducer from "./timeZoneReducer";
import workdayDataReducer from "./workdayDataReducer";
import { triggerReducer } from "./slices/triggers";
import customGridlayoutReducer from "./customGridlayoutReducer";
import interviewersPreferences from "./slices/interviewersPreferences";

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  eventsState: eventsReducer,
  interviewersState: interviewersReducer,
  jobApplicationState: jobApplicationReducer,
  interviewState: interviewReducer,
  interviewOptionsState: interviewOptionsReducer,
  interviewNotificationsState: interviewNotificationsReducer,
  interviewEmailsState: interviewEmailsReducer,
  interviewStartDateState: interviewStartDateReducer,
  emailTemplateState: emailTemplateReducer,
  timeZoneState: timeZoneReducer,
  conferencingOptionsState: conferencingOptionsReducer,
  clientLocationState: clientLocationReducer,
  clientLocationsState: clientLocationsReducer,
  clientUsersState: clientUsersReducer,
  calendarState: calendarReducer,
  conferenceSelectedState: conferenceSelectionReducer,
  miscellaneousSettingsState: miscellaneousSettingsReducer,
  clientDocDataState: clientDocDataReducer,
  domainDocDataState: domainDocDataReducer,
  clientDashboardState: clientDashboardReducer,
  workdayDataState: workdayDataReducer,
  attachmentsState: interviewAttachmentsReducer,
  additionalClientPreferencesState: additionalClientPreferencesReducer,
  triggersState: triggerReducer,
  customGridlayoutReduxState: customGridlayoutReducer,
  alertPopupReducer,
  automationReducer,
  domainsReducer,
  schedulingReducer,
  interviewersPreferences
});

// const persistConfig = {
//   key: "customGridlayoutReduxState",
//   storage,
//   whitelist: ["customGridlayoutReduxState"],
//   stateReconciler: autoMergeLevel2
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default rootReducer;
