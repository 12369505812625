const INITIAL_STATE = {
  emailTemplate: null
};

const applySetEmailTemplate = (state, action) => ({
  ...state,
  emailTemplate: action.emailTemplate
});

function emailTemplateReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "EMAIL_TEMPLATE_SET") {
    return applySetEmailTemplate(state, action);
  } else {
    return state;
  }
}

export default emailTemplateReducer;
