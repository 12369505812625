import React from "react";
import TWrapper from "../reactIntegration/TWrapper";
import { isString } from "lodash";

const InjectTranslationKey = {
  type: "postProcessor",
  name: "InjectTranslationKey",
  process: (value, key, options) => {
    const isEditMode = window.isEditMode === true;

    if (isEditMode) {
      if (options.disableEdit === true) {
        return value;
      }
      const e = React.createElement;
      const isTrans = value.includes("</");
      if (isTrans) {
        // is <Trans> call
        return `<span>${key[0]}</span> ${value}`;
      }
      // is t() call
      return e(
        TWrapper,
        {
          id: key[0],
          value,
          isEditMode,
          count: options?.count === "0" ? "0" : options?.count ?? "1"
        },
        null
      );
    }

    return value;
  }
};

export default InjectTranslationKey;
