const INITIAL_STATE = {
  views: {},
  newViewLabel: "",
  isMenuOpened: false,
  menuAnchorEl: null,
  activeViewId: null
};
const customGridlayoutReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case "createView": {
      const id = Math.random().toString();

      return {
        ...state,
        activeViewId: id,
        newViewLabel: "",
        views: {
          ...state.views,
          [id]: { label: state.newViewLabel, value: action.value }
        }
      };
    }

    case "deleteView": {
      const views = Object.fromEntries(
        Object.entries(state.views).filter(([id]) => id !== action.id)
      );

      let activeViewId;
      if (state.activeViewId !== action.id) {
        activeViewId = state.activeViewId;
      } else {
        const viewIds = Object.keys(state.views);

        if (viewIds.length === 0) {
          activeViewId = null;
        } else {
          activeViewId = viewIds[0];
        }
      }

      return {
        ...state,
        views,
        activeViewId
      };
    }

    case "setActiveView": {
      return {
        ...state,
        activeViewId: action.id,
        isMenuOpened: false
      };
    }

    case "setNewViewLabel": {
      return {
        ...state,
        newViewLabel: action.label
      };
    }

    case "togglePopper": {
      return {
        ...state,
        isMenuOpened: !state.isMenuOpened,
        menuAnchorEl: action.element
      };
    }

    case "closePopper": {
      return {
        ...state,
        isMenuOpened: false
      };
    }

    default: {
      return state;
    }
  }
};

export default customGridlayoutReducer;
