/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  isAvailabilityMode: boolean | null;
  isTeamsSchedulingMode: boolean | null;
  workdayComments: string | null;
}
const initialState: IInitialState = {
  isAvailabilityMode: null,
  isTeamsSchedulingMode: null,
  workdayComments: null
};

export const schedulingSlice = createSlice({
  name: "scheduling",
  initialState,
  reducers: {
    setIsAvailabilityMode: (state, action: PayloadAction<boolean | null>) => {
      const { payload } = action;
      state.isAvailabilityMode = payload;
    },
    setIsTeamsSchedulingMode: (
      state,
      action: PayloadAction<boolean | null>
    ) => {
      const { payload } = action;
      state.isTeamsSchedulingMode = payload;
    },
    setWorkdayComments: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      state.workdayComments = payload;
    }
  }
});

export const {
  setIsAvailabilityMode,
  setIsTeamsSchedulingMode,
  setWorkdayComments
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
