import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import InjectTranslationKey from "./postprocessor/InjectTranslationKey";
import { fallbackLanguage, loadPath } from "./config";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(InjectTranslationKey)
  .init({
    fallbackLng: [fallbackLanguage],
    backend: {
      loadPath: lngs => {
        if (i18n.options?.customerId) {
          return loadPath.replace(
            "{{lng}}",
            `${lngs[0]}_${i18n.options?.customerId}`
          );
        }
        return loadPath;
      },
      crossDomain: true
    },
    load: "currentOnly",
    baseLng: "",
    customerId: "",
    debug: false,
    nonExplicitSupportedLngs: true,
    postProcess: ["InjectTranslationKey"],
    returnObjects: true,
    interpolation: { escapeValue: false },
    returnEmptyString: false,
    react: {
      bindI18n: "languageChanged editorSaved",
      transKeepBasicHtmlNodesFor: ["span"]
      //  defaultTransParent: WrapperComp
    }
  });

export default i18n;
