export const CONFERENCING_OPTIONS_INITIAL_STATE = {
  conferencingOptions: {
    sameConferenceAcrossInterview: true,
    showZoomLinkToCandidate: true,
    emailGroups: [],
    reminders: [],
    zoom: {
      meetingSettings: {
        meetingID: "generated",
        requirePassword: true,
        password: "",
        video: {
          host: false,
          participant: false
        },
        audio: "both",
        options: {
          enableJoinBeforeHost: false,
          muteParticipantsUponEntry: false,
          enableWaitingRoom: false,
          onlyAuthenticated: false,
          recordMeetingLocally: false
        },
        alternativeHosts: true,
        scheduleFor: ""
      }
    },
    webex: {
      meetingSettings: {
        accessControlOptions: {
          enforcePassword: true
        },
        enableOptions: {
          meetingRecord: false
        },
        attendeeOptions: {
          emailInvitations: false
        },
        remind: {
          enableReminder: true,
          sendEmail: false,
          sendMobile: false
        },
        password: "",
        alternativeHosts: true
      }
    }
  }
};

export const SET_CONFERENCING_OPTIONS_ACTION = "SET_CONFERENCING_OPTIONS";
const applySetConferencingOptions = (state, action) => ({
  conferencingOptions: {
    ...state.conferencingOptions,
    ...action.conferencingOptions
  }
});

export const RESET_CONFERENCING_OPTIONS_ACTION = "RESET_CONFERENCING_OPTIONS";
const resetConferencingOptions = () => {
  return { ...CONFERENCING_OPTIONS_INITIAL_STATE };
};

function conferencingOptionsReducer(state, action) {
  if (!state) {
    return CONFERENCING_OPTIONS_INITIAL_STATE;
  }
  if (action.type === SET_CONFERENCING_OPTIONS_ACTION) {
    return applySetConferencingOptions(state, action);
  }
  if (action.type === RESET_CONFERENCING_OPTIONS_ACTION) {
    return resetConferencingOptions();
  }
  return state;
}

export default conferencingOptionsReducer;
