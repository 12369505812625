import { Box, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, pure } from "recompose";
import type { IHeaderProps } from "../../types/Header";
import dayjs from "dayjs";

function SandboxHeader(props: any): React.ReactElement {
  const {
    authUser,
    clientDoc
  }: {
    authUser: IHeaderProps["authUser"];
    clientDoc: {
      integrations: {
        webex: {
          conference: boolean;
        };
      };
    };
  } = props;
  const getColor = (): string => {
    let color = "#E7A150";
    if (
      authUser?.client?.includes("_gms1") ||
      !Number.isNaN(
        Number(authUser?.client?.substring(authUser.client.length - 1))
      )
    ) {
      color = "#32855F";
    } else if (authUser.client.includes("_preview")) {
      color = "#ABCC68";
    }

    return color;
  };

  const getLabel = (): string => {
    let label = "Sandbox";
    if (authUser.client?.includes("_gms1")) {
      label = "Implementation Preview";
    } else if (authUser.client.includes("_preview")) {
      label = "Sandbox Preview";
    } else if (
      !Number.isNaN(
        Number(authUser.client?.substring(authUser.client.length - 1))
      )
    ) {
      label = "Implementation";
    }
    return label;
  };
  return (
    <>
      {authUser && (
        <>
          <Box>
            {authUser?.client && import.meta.env.REACT_APP_ENV === "sandbox" ? (
              <div
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "white",
                  backgroundColor: getColor(),
                  width: "100%"
                }}
              >
                &nbsp;
                {getLabel()} - {authUser.client}
              </div>
            ) : (
              ""
            )}
          </Box>
          {authUser?.client && authUser.client === "activision" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                Your Rooster subscription is ending on December 15, 2024
                <span style={{ color: "red" }}>
                  {(() => {
                    const today = dayjs();
                    const deadline = dayjs().month(11).date(15);
                    const diffDays = deadline.diff(today, "day");
                    return ` (in ${diffDays} days). `;
                  })()}
                </span>
                After this, interviews must be managed outside Rooster.
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser,
  clientDoc: state.clientDocDataState.clientDocData
});

export default compose(
  pure,
  withRouter,
  connect(mapStateToProps)
)(SandboxHeader);
