const INITIAL_STATE = {
  selected: {
    zoom: false,
    webex: false
  },
  enabled: {
    zoom: undefined,
    webex: undefined,
    sms: undefined,
    msteams: undefined
  }
};

const applySetZoomSelect = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    zoom: action.selected
  }
});

const applySetWebexSelect = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    webex: action.selected
  }
});

const applySetConferencesEnabled = (state, action) => ({
  ...state,
  enabled: {
    ...state.enabled,
    ...action.enabled
  }
});

const applySetSmsEnabled = (state, action) => ({
  ...state,
  enabled: {
    ...state.enabled,
    sms: action.enabled
  }
});

export const SET_ZOOM_SELECTION_ACTION = "SET_ZOOM_SELECTION";
export const SET_WEBEX_SELECTION_ACTION = "SET_WEBEX_SELECTION";
export const SET_CONFERENCES_ENABLED_ACTION = "SET_CONFERENCES_ENABLED";
export const SET_SMS_ENABLED_ACTION = "SET_SMS_ENABLED";

function conferenceSelectionReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }

  if (action.type === "SET_ZOOM_SELECTION") {
    return applySetZoomSelect(state, action);
  }
  if (action.type === "SET_WEBEX_SELECTION") {
    return applySetWebexSelect(state, action);
  }
  if (action.type === "SET_CONFERENCES_ENABLED") {
    return applySetConferencesEnabled(state, action);
  }
  if (action.type === "SET_SMS_ENABLED") {
    return applySetSmsEnabled(state, action);
  }
  return state;
}

export default conferenceSelectionReducer;
