const INITIAL_STATE = {
  calendar: null
};

const applySetCalendar = (state, action) => ({
  ...state,
  calendar: action.calendar
});

function calendarReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === "CALENDAR_SET") {
    return applySetCalendar(state, action);
  }
  return state;
}

export default calendarReducer;
