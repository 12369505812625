import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { truncate } from "lodash";
import moment from "moment-timezone";
import React from "react";
import InterviewIcons from "../../../components/helpers/InterviewIcons";
import {
  CLIENTS,
  CONFERENCING,
  LOCATIONS,
  WORKDAY_DATA
} from "../../../constants/databaseTables";
import { interviewTypeIconHelper } from "../../../components/helpers/interviewTypeIconsHelper";

export const getWorkdayDataByTypeFromFirestore = async (db, client, type) => {
  try {
    const doc = await db
      .collection(CLIENTS)
      .doc(client)
      .collection(WORKDAY_DATA)
      .doc(type)
      .get();

    if (!doc.exists) {
      return [];
    }

    return doc.data()?.[type] ?? [];
  } catch (err) {
    console.error(err?.message);
  }
  return [];
};

export const getRolesOptionsFromFirestore = async (db, client) => {
  const roles = await getWorkdayDataByTypeFromFirestore(db, client, "roles");

  if (roles?.length === 0) {
    return [];
  }

  return roles
    ?.map(({ roleName, workdayID } = {}) => {
      if (!(roleName && workdayID)) {
        return undefined;
      }

      return {
        name: roleName,
        value: workdayID,
        key: workdayID,
        label: (
          <div
            style={{
              padding: "2px",
              whiteSpace: "nowrap"
            }}
          >
            <FontAwesomeIcon fixedWidth icon="user-tag" />
            &nbsp;{roleName}
          </div>
        ),
        type: "role"
      };
    })
    ?.filter(role => role)
    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
};

const truncateMiddle = (fullString, stringLength, omission) => {
  if (fullString.length <= stringLength) {
    return fullString;
  }

  const separator = omission || "...";

  const separatorLength = separator.length;
  const charsToShow = stringLength - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullString.substr(0, frontChars) +
    separator +
    fullString.substr(fullString.length - backChars)
  );
};

export const getSkillsOptionsFromFirestore = async (db, client) => {
  const skills = await getWorkdayDataByTypeFromFirestore(db, client, "skills");

  if (skills?.length === 0) {
    return [];
  }

  return skills
    ?.map(skill => {
      const { type, name } = skill ?? {};

      if (!(name && type)) {
        return undefined;
      }

      const icon = InterviewIcons(type);

      return {
        ...skill,
        icon,
        label: (
          <div
            style={{
              padding: "2px",
              whiteSpace: "nowrap"
            }}
          >
            <FontAwesomeIcon fixedWidth icon={icon} />
            &nbsp;{truncateMiddle(name, 35, "...")}
          </div>
        )
      };
    })
    ?.filter(skill => skill)
    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
};

const recursivelyGet = (obj, keys) => {
  const key = keys.shift();
  const newObj = obj?.[key];

  if (keys.length === 0) {
    return newObj;
  }

  return recursivelyGet(newObj, keys);
};

const paginateQueries = async (
  client,
  ref,
  docs,
  orderBy,
  orderBySecondary,
  limit,
  startAfter
) => {
  const first = startAfter
    ? ref
        .orderBy(orderBy)
        .orderBy(orderBySecondary)
        .startAfter(...startAfter)
        .limit(limit)
    : ref.orderBy(orderBy).orderBy(orderBySecondary).limit(limit);

  const snapshot = await first.get();
  const docsCount = snapshot.docs.length;
  const { docs: documents = [] } = snapshot;

  if (documents.length > 0) {
    docs.push(...documents);
  }

  if (snapshot.empty || docsCount < limit) {
    return docs;
  }

  const last = documents[docsCount - 1];
  const lastData = last.data();
  const queryCursor = [
    lastData?.[orderBy],
    recursivelyGet(lastData, orderBySecondary.split("."))
  ];

  return paginateQueries(
    client,
    ref,
    docs,
    orderBy,
    orderBySecondary,
    limit,
    queryCursor
  );
};

export const getLoadDocs = async (
  db,
  client,
  collection,
  [key, operator, value] = []
) => {
  let query = db.collection(CLIENTS).doc(client).collection(collection);
  if (key && operator) {
    query = query.where(key, operator, value);
  }

  try {
    const docs = await paginateQueries(
      client,
      query,
      [],
      "key",
      "updated",
      500
    );

    return docs?.map(doc => doc.data());
  } catch (err) {
    console.error(err);
  }

  return [];
};

export const getLocationsDocs = async (
  db,
  client,
  [key, operator, value] = []
) => {
  try {
    const locations = await getLoadDocs(db, client, LOCATIONS, [
      key,
      operator,
      value
    ]);

    if (locations.length === 0) {
      throw new Error();
    }

    return locations;
  } catch {
    return getWorkdayDataByTypeFromFirestore(db, client, LOCATIONS);
  }
};

export const getConferencingDocs = async (
  db,
  client,
  [key, operator, value] = []
) => getLoadDocs(db, client, CONFERENCING, [key, operator, value]);

export const getLocationsFromFirestore = async (db, client) => {
  const locations = await getLocationsDocs(db, client);
  locations.unshift({
    value: "",
    name: "(Not Specified)",
    timeZone: moment.tz.guess()
  });

  return locations;
};

export const getConferencingOptionsFromFirestore = async (db, client) => {
  const conferencing = await getConferencingDocs(db, client);
  if (conferencing?.length === 0) {
    return [];
  }

  return conferencing
    .map(conference => {
      const { icon, name, type } = conference;
      // If icon is available then use it. If not, use the name to determine the icon.
      const updatedIcon = !!icon
        ? icon
        : !!name
        ? interviewTypeIconHelper(name)
        : null;

      if (!(name && updatedIcon)) {
        return undefined;
      }

      if (type === "conferencing") {
        return {
          ...conference,
          icon: updatedIcon,
          label: (
            <div style={{ padding: "2px", whiteSpace: "nowrap" }}>
              <FontAwesomeIcon fixedWidth icon={updatedIcon} />
              &nbsp;{truncate(name, { length: 20, omission: "..." })}
            </div>
          )
        };
      }

      return conference;
    })
    ?.filter(conference => conference)
    ?.sort((a, b) => (a?.type > b?.type || a?.name > b?.name ? 1 : -1));
};
