const INITIAL_STATE = {
  authUser: null,
  userProcessing: false,
  firebaseAuthLoading: true,
  i18nEditMode: false
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser
});

const applySetUserProcessing = (state, action) => ({
  ...state,
  userProcessing: action.payload
});

const applySetFirebaseAuthLoading = (state, action) => ({
  ...state,
  firebaseAuthLoading: action.payload
});

const applyI18nEditMode = (state, action) => ({
  ...state,
  i18nEditMode: action.payload
});

export const AUTH_USER_SET = "AUTH_USER_SET";
export const USER_PROCESSING_SET = "USER_PROCESSING_SET";
export const FIREBASE_AUTH_LOADING_SET = "FIREBASE_AUTH_LOADING_SET";
export const I18N_EDIT_MODE_SET = "I18N_EDIT_MODE_SET";

function sessionReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === AUTH_USER_SET) {
    return applySetAuthUser(state, action);
  }

  if (action.type === USER_PROCESSING_SET) {
    return applySetUserProcessing(state, action);
  }

  if (action.type === FIREBASE_AUTH_LOADING_SET) {
    return applySetFirebaseAuthLoading(state, action);
  }

  if (action.type === I18N_EDIT_MODE_SET) {
    return applyI18nEditMode(state, action);
  }

  return state;
}

export default sessionReducer;
