import React, { useState } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { themeCreator } from "./base";

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC = props => {
  const curThemeName = localStorage.getItem("appTheme") || "LightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (name: string): void => {
    localStorage.setItem("appTheme", name);
    _setThemeName(name);
  };
  const { children } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
