const INITIAL_STATE = {
  attachments: null
};

const applySetInterviewAttachments = (state, action) => ({
  ...state,
  attachments: { ...state.attachments, ...action.attachments }
});

const applyResetInterviewAttachments = () => INITIAL_STATE;

export const INTERVIEW_ATTACHMENTS_SET_ACTION = "INTERVIEW_ATTACHMENTS_SET";
export const INTERVIEW_ATTACHMENTS_RESET_ACTION = "INTERVIEW_ATTACHMENTS_RESET";

const interviewAttachmentsReducer = (state, action) => {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === INTERVIEW_ATTACHMENTS_SET_ACTION) {
    return applySetInterviewAttachments(state, action);
  }
  if (action.type === INTERVIEW_ATTACHMENTS_RESET_ACTION) {
    return applyResetInterviewAttachments();
  }
  return state;
};

export default interviewAttachmentsReducer;
