import { Stack } from "@mui/material";

export default function RoosterLoader({ height }: { height: string }) {
  return (
    <Stack height={height} justifyContent="center" alignItems="center">
      <img
        src="/rooster-animate-logo.gif"
        alt="Rooster Loader"
        loading="lazy"
      />
    </Stack>
  );
}
