import * as React from "react";
import { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useListTags } from "./services/hooks";
import { Tag } from "src/@generated/graphql";
import { Alert, Chip, CircularProgress, Tooltip } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_TAG } from "./services/tags.gql";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { isValidTag } from "./services/helpers";

type TagSelectProps = {
  value: Tag[];
  onChange: (value: Tag[]) => void;
  allowCreate?: boolean;
  fetchArchived?: boolean;
};
export default function TagsSelect(props: TagSelectProps) {
  const { t } = useTranslation();
  const { onChange, allowCreate, fetchArchived = false } = props;
  const [value, setValue] = React.useState<Tag[] | undefined>(
    props?.value ?? []
  );
  const [open, toggleOpen] = React.useState(false);
  const filter = createFilterOptions<Tag>();

  const [createTag, { data: createdTag, loading, error: errorCreateTag }] =
    useMutation(CREATE_TAG);

  const { client, id, uid } = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [errorCode, setErrorCode] = React.useState<{
    code: string;
    name: string;
  } | null>(null);

  const {
    data: optionsData,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
    refetch
  } = useListTags(searchTerm, fetchArchived);

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 500),
    [refetch]
  );

  const onTagListUpdate = (_, values, inputType, selected) => {
    if (errorCode) setErrorCode(null);

    const newTagName = selected?.option?.inputText?.trim();

    if (newTagName && allowCreate) {
      const isValid = isValidTag(newTagName);
      if (!isValid) {
        setErrorCode({
          code: "INVALID_TAG_NAME",
          name: newTagName
        });
        return;
      } else {
        setErrorCode(null);
      }
      createTag({
        variables: {
          input: {
            clientId: client,
            name: newTagName,
            type: ["INTERVIEWER"],
            archived: false,
            createdBy: uid ?? id
          }
        }
      })
        .then(({ data }) => {
          values.pop();
          values.push({
            id: data?.createTag.id ?? "",
            clientId: client,
            name: newTagName,
            type: ["INTERVIEWER"],
            archived: false,
            createdAt: new Date().toISOString(),
            createdBy: uid ?? id
          });
          setValue(values as Tag[]);
          onChange(values as Tag[]);
        })
        .catch((e) =>
          setErrorCode({
            code: e?.message,
            name: newTagName
          })
        );
    } else {
      setValue(values as Tag[]);
      onChange(values as Tag[]);
    }
  };

  const onTagSearchTermUpdate = (event, newInputValue) => {
    setErrorCode(null);
    const newTagName = newInputValue.trim();
    setSearchTerm(newTagName);
    debouncedRefetch();
  };

  return (
    <>
      {errorCode?.code === "INVALID_TAG_NAME" && allowCreate && (
        <Alert
          severity="error"
          sx={{
            marginBottom: "1rem"
          }}
        >
          {t("ROOSTER.TAGS.INVALID_TAG_NAME", {
            defaultValue:
              "Invalid tag name. Please ensure the input is between 3 to 50 characters, starts and ends with a letter or number, and only contains letters, numbers, spaces, underscores (_), and hyphens (-)."
          })}
        </Alert>
      )}
      <Autocomplete
        multiple
        size={"small"}
        id="tags-outlined"
        value={value}
        onChange={onTagListUpdate}
        freeSolo={!!allowCreate}
        options={optionsData?.tags || []}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        loading={tagsLoading || tagsFetching}
        defaultValue={[]}
        limitTags={3}
        sx={{ minWidth: 200 }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (allowCreate) {
            const { inputValue } = params;

            const isExisting = options.some(
              (option) => inputValue === option.name
            );
            if (
              inputValue !== "" &&
              !isExisting &&
              errorCode?.code !== "INVALID_TAG_NAME"
            ) {
              filtered.push({
                inputText: params.inputValue?.trim(),
                name: `Create New: "${params.inputValue?.trim()}"`
              });
            }
          }
          return (
            filtered?.filter((v) => !value?.some((t) => t.id === v.id)) ?? []
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const chip = (
              <Chip
                label={
                  option?.name?.length > 20
                    ? option?.name?.slice(0, 20) + "..."
                    : option?.name
                }
                {...getTagProps({ index })}
                size="small"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              />
            );
            return option?.name?.length > 20 ? (
              <Tooltip key={index} title={option?.name}>
                {chip}
              </Tooltip>
            ) : (
              chip
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("ROOSTER.COMMON.TAGS", {
              defaultValue: "Tags"
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {tagsLoading || tagsFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              whiteSpace: "normal", // Allow wrapping of text
              wordBreak: "break-all", // Break long words without spaces
              overflowWrap: "break-word" // Ensure wrapping for long words
            }}
          >
            {option?.name}
          </li>
        )}
        onInputChange={onTagSearchTermUpdate}
      />
      {errorCode?.code === "DUPLICATE_TAG" && (
        <Alert severity="error">
          {t("ROOSTER.TAGS.DUPLICATE-TAG-ERROR", {
            defaultValue:
              "Duplicate tag! Tag {{errorCodeName}} already exists and is probably archived",
            errorCodeName: errorCode.name
          })}
        </Alert>
      )}
    </>
  );
}
