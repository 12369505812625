const INITIAL_STATE = {};

const applySetDomainDocData = (state, action) => ({
  ...state,
  ...action.doc
});

export const SET_DOMAIN_DOC_ACTION = "SET_DOMAIN_DOC";
function domainDocDataReducer(state, action) {
  if (!state) {
    return INITIAL_STATE;
  }
  if (action.type === SET_DOMAIN_DOC_ACTION) {
    return applySetDomainDocData(state, action);
  }
  return state;
}

export default domainDocDataReducer;
