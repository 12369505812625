import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@emotion/css";
import { Button } from "react-bootstrap";

const Header = ({ isMinimized, selectedKey, toggleView, editModeHandler }) => {
  return (
    <div
      className="d-flex flex-row justify-content-between p-2"
      style={{ backgroundColor: "#25aae1" }}
    >
      <span className="text-white d-flex flex-row align-items-center">
        <FontAwesomeIcon
          className="mr-2 ml-2"
          style={{ color: "white" }}
          icon="language"
          size="2x"
          fixedWidth
        />
        <div
          className={`${css`
            background-color: rgba(255, 255, 255, 0.1);
            font-size: 1.15em;
            font-weight: 600;
          `} pl-2 pr-2 rounded-lg`}
        >
          Translation Editor
        </div>
        <div
          className={`${css`
            background-color: rgba(255, 255, 255, 0.1);
            font-size: 1.15em;
            font-weight: 500;
          `} ml-2 pl-2 pr-2 rounded-lg`}
        >
          {selectedKey}
        </div>
      </span>
      <span className="d-flex flex-row justify-content-end">
        <Button
          className="mr-2"
          size="sm"
          style={{
            backgroundColor: "white",
            borderColor: "white",
            borderRadius: "5px"
          }}
          onClick={toggleView}
        >
          {isMinimized ? (
            <FontAwesomeIcon
              style={{
                color: "black"
              }}
              icon="angle-up"
              size="sm"
              fixedWidth
            />
          ) : (
            <FontAwesomeIcon
              style={{
                color: "black"
              }}
              icon="angle-down"
              size="sm"
              fixedWidth
            />
          )}
        </Button>
        <Button
          className="mr-2"
          size="sm"
          style={{
            backgroundColor: "white",
            borderColor: "white",
            borderRadius: "5px"
          }}
          onClick={() => {
            editModeHandler();
          }}
        >
          <FontAwesomeIcon
            style={{
              color: "black"
            }}
            icon="times"
            size="sm"
            fixedWidth
          />
        </Button>
      </span>
    </div>
  );
};

export default Header;
