const INITIAL_STATE = {
  interview: null,
  currentStep: 0,
  savedInterviewId: null,
  sendingInterview: null,
  reason: null
};

export const INTERVIEW_SET_ACTION = "INTERVIEW_SET";
const applySetInterview = (state, action) => ({
  ...state,
  interview: action.interview
});

export const SET_ORIGINAL_INTERVIEW_ACTION = "SET_ORIGINAL_INTERVIEW";
const setOriginalInterview = (state, action) => ({
  ...state,
  originalInterview: action.originalInterview
});

export const SET_INTERVIEW_CURRENT_STEP_ACTION = "SET_INTERVIEW_CURRENT_STEP";
const setInterviewCurrentStep = (state, action) => ({
  ...state,
  currentStep: action.currentStep
});

export const RESET_INTERVIEW_CURRENT_STEP_ACTION =
  "RESET_INTERVIEW_CURRENT_STEP_ACTION";
const resetInterviewCurrentStep = state => ({
  ...state,
  currentStep: 0
});

export const SET_INTERVIEW_SEND_EMAIL_ACTION = "SET_INTERVIEW_SEND_EMAIL";
const setInterviewSendEmail = (state, action) => {
  const { interviewIndex, sendEmail } = action;

  const updatedInterviews = state.interview.map((item, idx) => {
    if (idx !== interviewIndex) {
      return item;
    }

    const isBreak = (item.interviewers ?? []).length === 0;

    if (isBreak) {
      return item;
    }

    return {
      ...item,
      event: {
        ...item.event,
        send: {
          ...item.event.send,
          email: sendEmail
        }
      }
    };
  });

  return { ...state, interview: updatedInterviews };
};

export const SET_INTERVIEW_SEND_INVITATION_ACTION =
  "SET_INTERVIEW_SEND_INVITATION";
const setInterviewSendInvitation = (state, action) => {
  const { interviewIndex, sendInvitation } = action;

  const updatedInterviews = state.interview.map((item, idx) => {
    if (idx !== interviewIndex) {
      return item;
    }

    return {
      ...item,
      event: {
        ...item.event,
        send: {
          ...item.event.send,
          event: sendInvitation
        }
      }
    };
  });

  return { ...state, interview: updatedInterviews };
};

export const SET_INTERVIEW_SAVED_ID_ACTION = "SET_INTERVIEW_SAVED_ID";
const setInterviewSavedId = (state, action) => {
  const { savedInterviewId } = action;
  return {
    ...state,
    savedInterviewId
  };
};

export const SET_SENDING_INTERVIEW_ACTION = "SET_SENDING_INTERVIEW";
const setSendingInterview = (state, action) => ({
  ...state,
  sendingInterview: action.sendingInterview
});

export const SET_REASON_INTERVIEW_ACTION = "SET_REASON_INTERVIEW";
const setReason = (state, action) => ({
  ...state,
  reason: action.reason
});

export const SET_EVENT_CHANGE_NOTES_INTERVIEW_ACTION =
  "SET_EVENT_CHANGE_NOTES_INTERVIEW";
const setChangeNotes = (state, action) => ({
  ...state,
  notes: action.notes
});

export const SET_INTERVIEW_SEND_EMAIL_ACTION_FOR_ALL_INTERVIEWERS =
  "SET_INTERVIEW_SEND_EMAIL_ACTION_FOR_ALL_INTERVIEWERS";
export const setInterviewSendEmailForAllInterviewers = (state, action) => {
  const { sendEmail } = action;
  const updatedInterviews = state?.interview?.map(item => {
    const isBreak = (item.interviewers ?? []).length === 0;
    if (isBreak) {
      return item;
    }
    return {
      ...item,
      event: {
        ...item.event,
        send: {
          ...item.event.send,
          email: sendEmail ?? false
        }
      }
    };
  });

  return updatedInterviews ? { ...state, interview: updatedInterviews } : state;
};

function interviewReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === INTERVIEW_SET_ACTION) {
    return applySetInterview(state, action);
  }
  if (action.type === SET_ORIGINAL_INTERVIEW_ACTION) {
    return setOriginalInterview(state, action);
  }
  if (action.type === SET_INTERVIEW_CURRENT_STEP_ACTION) {
    return setInterviewCurrentStep(state, action);
  }
  if (action.type === RESET_INTERVIEW_CURRENT_STEP_ACTION) {
    return resetInterviewCurrentStep(state);
  }
  if (action.type === SET_INTERVIEW_SEND_EMAIL_ACTION) {
    return setInterviewSendEmail(state, action);
  }
  if (action.type === SET_INTERVIEW_SEND_INVITATION_ACTION) {
    return setInterviewSendInvitation(state, action);
  }
  if (action.type === SET_INTERVIEW_SAVED_ID_ACTION) {
    return setInterviewSavedId(state, action);
  }
  if (action.type === SET_SENDING_INTERVIEW_ACTION) {
    return setSendingInterview(state, action);
  }
  if (action.type === SET_REASON_INTERVIEW_ACTION) {
    return setReason(state, action);
  }
  if (action.type === SET_EVENT_CHANGE_NOTES_INTERVIEW_ACTION) {
    return setChangeNotes(state, action);
  }
  if (action.type === SET_INTERVIEW_SEND_EMAIL_ACTION_FOR_ALL_INTERVIEWERS) {
    return setInterviewSendEmailForAllInterviewers(state, action);
  }
  return state;
}

export default interviewReducer;
