import { getServerUrl } from "../constants";

export const MINIMUM_SEARCH_STRING_LENGTH = 3;

const isLoadBalanced = [
  "production-europe",
  "sandbox-europe",
  "production",
  "sandbox",
  "impl"
].includes(import.meta.env.REACT_APP_ENV);

const regionId = import.meta.env.REACT_APP_ENV.includes("europe")
  ? "europe-west1"
  : "us-central1";

const functionsBasePath = isLoadBalanced
  ? `${getServerUrl()}/functions`
  : document.location.hostname === "localhost"
  ? import.meta.env.REACT_APP_MY_LOCAL_URL
    ? `${import.meta.env.REACT_APP_MY_LOCAL_URL}/${
        import.meta.env.REACT_APP_PROJECT_ID
      }/${regionId}`
    : `http://localhost:5001/${
        import.meta.env.REACT_APP_PROJECT_ID
      }/${regionId}`
  : `https://${regionId}-${
      import.meta.env.REACT_APP_PROJECT_ID
    }.cloudfunctions.net`;

const BASE_URL =
  document.location.hostname === "localhost"
    ? import.meta.env.REACT_APP_MY_LOCAL_URL
      ? `${import.meta.env.REACT_APP_MY_LOCAL_URL}/${
          import.meta.env.REACT_APP_PROJECT_ID
        }/${regionId}`
      : `http://localhost:5001/${
          import.meta.env.REACT_APP_PROJECT_ID
        }/${regionId}`
    : functionsBasePath;

export const GCF = {
  BASE_URL,
  ROUTE: {
    AUTH: {
      BASE_URL: "auth",
      LOGIN: "login",
      CALENDAR_GRANTS: "grants/calendar",
      CONFERENCE_GRANTS: "grants/conference",
      USER: {
        BASE_URL: "user",
        FIREBASEUSER: "firebase",
        SET_CUSTOM_CLAIMS: "setCustomClaims"
      }
    },
    CALENDAR: {
      BASE_URL: "calendar",
      AVAILABLE: "available",
      AVAILABILITY: "availability"
    },
    INSIGHTS: {
      BASE_URL: "insights",
      PREPARE: "prepare"
    },
    INTEGRATIONS: {
      BASE_URL: "integrations",
      WORKDAY: "workday",
      JOB_DESCRIPTION_PDF: "pdf/job-description"
    },
    RESPOND: {
      BASE_URL: "respond",
      CONFIRM: "confirm"
    },
    SIMPLE_QUERY: {
      BASE_URL: "simpleQuery",
      USERS_BY_PATTERN: "usersByPattern",
      USERS_BY_FIELD: "usersByField",
      COUNT: "count",
      DEPARTMENTS: "departments",
      TITLES: "titles"
    },
    SMS: {
      BASE_URL: "sms",
      ACCOUNT: {
        BASE_URL: "account",
        BILLING: "billing"
      },
      NUMBERS: {
        BASE_URL: "numbers",
        FETCH_AVAILABLE: "available"
      }
    },
    TRANSLATE: {
      BASE_URL: "translate",
      TEXT: "text"
    },
    VALIDATIONS: {
      BASE_URL: "validate",
      DOMAIN: "domain"
    },
    AVAILABILITY: {
      EVENTS: {
        CALENDAR: "availability/calendar/events",
        CONFERENCE: "availability/conference/events",
        CANDIDATE_AVAILABILITY: "availability/calendar/candidateAvailability"
      },
      AVAILABLE: {
        CALENDAR: "availability/calendar/available",
        CONFERENCE_AVAILABLE: "availability/calendar/conference/available",
        UNAUTHENTICATED_CALENDAR: "availability/calendar/candidateAvailable",
        CONFERENCE: "availability/conference/available"
      },
      PREFERENCES: {
        CANDIDATE_AVAILABILITY: "availability/calendar/candidateAvailability"
      },
      DEPRECATED: {
        AVAILABILITY: "availability/calendar/interviewerAvailability"
      },
      QUERY_AVAILABILITY: {
        PEOPLE: "queryAvailability/people"
      }
    },
    QUEUE: {
      PURGE: "queue/purge",
      PURGE_CLIENT: "queue/purgeClient"
    },
    DOMAIN: {
      TENANT_ID: "domain/tenantId"
    },
    SEARCH: {
      ROOMS: "rooms/search",
      ROOM_LIST: "rooms/list/search",
      ROOM_BY_ROOM_LIST: "rooms/list/getRooms",
      ROOM_LIST_USING_FIND_ROOMS: "rooms/list/find",
      ROOMS_USING_FIND_ROOMS: "rooms/find",
      ROOM_BY_ROOM_LIST_USING_FIND_ROOMS: "rooms/list/findRooms"
    },
    USER: {
      BASE_URL: "user",
      INVITE_COMPLETION: "invite/complete"
    },
    MULTI_DAY: {
      SCHEDULE: "multiDayScheduling/interceptor/scheduling",
      RESCHEDULE: "multiDayScheduling/interceptor/rescheduling",
      CANCEL: "multiDayScheduling/interceptor/cancel",
      CANDIDATE_SCHEDULE: "multiDayScheduling/interceptor/candidate/scheduling",
      RESEND: "multiDayScheduling/interceptor/resend"
    },
    INTERVIEWER_PREFERENCES: {
      GET_PREFERENCE: `${BASE_URL}/interviewerPreferences/preference`
    }
  }
};

export const FORCE_LOAD_ALL_USERS_AT_ONCE = false;
export const THRESHOLD_USERS_LOAD = 3000;
