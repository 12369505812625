/* eslint-disable import/extensions */
import { AxiosRequestConfig } from "axios";
import { getAxiosInstance } from "./axiosInstance";

export interface IAPIPayload {
  [key: string]: any;
}

export const get = async (
  apiEndpoint: string,
  data?: IAPIPayload,
  timeout?: number
) => {
  const axios = await getAxiosInstance(timeout ?? 60000);
  return axios.get(apiEndpoint, { ...(data ?? {}) });
};

export const getV2 = async (
  apiEndpoint: string,
  overrides = {} as AxiosRequestConfig
) => {
  const timeout = overrides.timeout ?? 60000;
  const axios = await getAxiosInstance(timeout);
  return axios.get(apiEndpoint, { ...overrides });
};

export const post = async (
  apiEndpoint: string,
  data?: IAPIPayload,
  config?: AxiosRequestConfig
) => {
  const timeout = config?.timeout ?? 60000;
  const axios = await getAxiosInstance(timeout);
  return axios.post(apiEndpoint, data, config);
};

export const put = async (apiEndpoint: string, data: IAPIPayload) => {
  const axios = await getAxiosInstance();
  return axios.put(apiEndpoint, data);
};

export const patch = async (apiEndpoint: string, data: IAPIPayload) => {
  const axios = await getAxiosInstance();
  return axios.patch(apiEndpoint, data);
};

export const deleteMethod = async (apiEndpoint: string, data?: IAPIPayload) => {
  const axios = await getAxiosInstance();
  return axios.delete(apiEndpoint, { data });
};
