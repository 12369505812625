import React, { useEffect, useState } from "react";

const Report = ({ setReport, baseText }) => {
  const [commentState, setCommentState] = useState("");
  const [correctionState, setCorrectionState] = useState("");

  useEffect(() => {
    setReport(commentState, correctionState);
  }, [commentState, correctionState]);

  return (
    <div className="d-flex flex-row justify-content-evenly align-items-center ml-3 mt-4">
      <div
        className="d-flex flex-column justify-content-stretch align-items-start mr-3"
        style={{ flex: 2 }}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-evenly mb-2"
          style={{ minWidth: "100%" }}
        >
          <div className="mr-2">💬 Comment</div>
        </div>
        <textarea
          className="p-2 rounded-lg"
          style={{
            minWidth: "100%",
            minHeight: "100px",
            borderColor: "#e2e8f0"
          }}
          value={commentState}
          placeholder="type your comment here..."
          onChange={event => {
            setCommentState(event.target.value);
          }}
        />
      </div>
      <div
        className="d-flex flex-column justify-content-stretch align-items-start mr-3"
        style={{ flex: 2 }}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-evenly mb-2"
          style={{ minWidth: "100%" }}
        >
          <div className="mr-2">✒️ Correction</div>
          <div
            className="d-flex flex-row justify-content-end"
            style={{ flex: 1 }}
          />
        </div>
        <textarea
          className="p-2 rounded-lg"
          style={{
            minWidth: "100%",
            minHeight: "100px",
            borderColor: "#e2e8f0"
          }}
          placeholder={baseText || "type your correction here..."}
          value={correctionState}
          onChange={event => {
            setCorrectionState(event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default Report;
