const INITIAL_STATE = {
  interviewEmails: null
};

const applySetInterviewEmails = (state, action) => ({
  ...state,
  interviewEmails: action.interviewEmails
});

export const SET_INTERVIEW_EMAILS_ACTION = "INTERVIEW_EMAILS_SET";
function interviewEmailsReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === "INTERVIEW_EMAILS_SET") {
    return applySetInterviewEmails(state, action);
  }
  return state;
}

export default interviewEmailsReducer;
