const INITIAL_STATE = {
  clientLocation: null
};

const applySetClientLocation = (state, action) => ({
  ...state,
  clientLocation: action.clientLocation
});

export const CLIENT_LOCATION_SET_ACTION = "CLIENT_LOCATION_SET";

function clientLocationReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "CLIENT_LOCATION_SET") {
    return applySetClientLocation(state, action);
  }
  return state;
}

export default clientLocationReducer;
