export const interviewTypeIconHelper = interviewType => {
  // case-insenstive searches for keywords to determine which icon to use.
  const optionContainsPerson = interviewType.search(/person/i);
  if (optionContainsPerson >= 0) {
    return "user";
  }
  const optionContainsUser = interviewType.search(/user/i);
  if (optionContainsUser >= 0) {
    return "user";
  }
  const optionContainsPhone = interviewType.search(/phone/i);
  if (optionContainsPhone >= 0) {
    return "phone-square-alt";
  }
  const optionContainsTelephone = interviewType.search(/telephone/i);
  if (optionContainsTelephone >= 0) {
    return "phone-square-alt";
  }
  const optionContainsVirtual = interviewType.search(/virtual/i);
  if (optionContainsVirtual >= 0) {
    return "webcam";
  }
  const optionContainsVideo = interviewType.search(/video/i);
  if (optionContainsVideo >= 0) {
    return "webcam";
  }
  return "circle";
};
