import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

export default function configureRoosterStore(initialState) {
  if (
    import.meta.env.REACT_APP_ENV === "production" &&
    document.location.hostname !== "localhost"
  ) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();
    return createStore(
      rootReducer,
      initialState,
      composeWithDevTools(
        applyMiddleware(thunk),
        applyMiddleware(LogRocket.reduxMiddleware()),
        sentryReduxEnhancer
      )
    );
  }
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
}
