/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DomainDocReduxType } from "@rooster/types";

interface IInitialState {
  domains: Record<string, DomainDocReduxType>;
}
const initialState: IInitialState = {
  domains: {}
};

export const domainSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    addDomainDocs: (state, action: PayloadAction<DomainDocReduxType[]>) => {
      const { payload } = action;
      const domainsMap = payload.reduce((acc, doc) => {
        const { id } = doc;
        acc[id] = doc;
        return acc;
      }, {} as Record<string, DomainDocReduxType>);
      state.domains = domainsMap;
    }
  }
});

export const { addDomainDocs } = domainSlice.actions;

export type DomainStateType = typeof initialState;

export default domainSlice.reducer;
