const INITIAL_STATE = {
  timeZone: null
};

const applySetTimeZone = (state, action) => ({
  ...state,
  timeZone: action.timeZone
});

export const TIME_ZONE_SET_ACTION = "TIME_ZONE_SET";

function timeZoneReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "TIME_ZONE_SET") {
    return applySetTimeZone(state, action);
  }
  return state;
}

export default timeZoneReducer;
