const INITIAL_STATE = {
  interviewStartDate: null
};

const applySetInterviewStartDate = (state, action) => ({
  ...state,
  interviewStartDate: action.interviewStartDate
});

export const INTERVIEW_STARTDATE_SET_ACTION = "INTERVIEW_STARTDATE_SET";
function interviewStartDateReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "INTERVIEW_STARTDATE_SET") {
    return applySetInterviewStartDate(state, action);
  }
  return state;
}

export default interviewStartDateReducer;
