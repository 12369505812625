import React from "react";
import { css } from "@emotion/css";
import { LazyLoadImage as LazyLoadFlagImage } from "react-lazy-load-image-component";
import { Popover, OverlayTrigger, Table } from "react-bootstrap";
import { fallbackLanguage } from "../../../config";

const Body = ({
  selectedPlural,
  isPluralDisabled,
  onEdit,
  onPluralCategorySelect,
  i18n,
  setReportMode,
  pluralCategories,
  textAreaText,
  fallBackLang
}) => {
  const showBaseTranslationFallbacklng = ["one", "other"].includes(
    selectedPlural
  );
  const textAreaH = "50px";
  const textAreaHRight =
    showBaseTranslationFallbacklng && i18n.language !== fallbackLanguage
      ? "170px"
      : textAreaH;
  return (
    <div className="d-flex flex-row justify-content-evenly align-items-start ml-3 mt-4">
      <div
        className="d-flex flex-column justify-content-stretch align-items-stretch mr-3"
        style={{ flex: 2 }}
      >
        {showBaseTranslationFallbacklng && (
          <div className="d-flex flex-column justify-content-stretch align-items-stretch mb-3">
            <div
              className="d-flex flex-row align-items-center justify-content-evenly mb-2"
              style={{ minWidth: "100%" }}
            >
              <LazyLoadFlagImage
                className="ml-1"
                alt={i18n.language}
                src={`/assets/images/flags/${"en"}.png`}
                style={{
                  border: "1px solid lightgray",
                  minWidth: "25px",
                  maxWidth: "25px",
                  minHeight: "15px",
                  maxHeight: "15px"
                }}
              />
              <div className="mr-2 ml-2">Base translation ({fallBackLang})</div>
              <div
                className="d-flex flex-row justify-content-end"
                style={{ flex: 1 }}
              >
                <button
                  type="button"
                  className={`${css`
                    font-size: 12px !important;
                    font-weight: 600;
                    border: 1px solid #edf2f7;
                    padding: 2px 6px 2px 6px;
                    color: black;
                  `} mr-2 rounded-lg`}
                  onClick={() => {
                    window.navigator.clipboard.writeText(textAreaText.base);
                  }}
                >
                  Copy
                </button>
                <button
                  type="button"
                  className={`${css`
                    font-size: 12px !important;
                    font-weight: 600;
                    border: 1px solid #e53e3e;
                    background: white;
                    padding: 2px 5px 2px 5px;
                    color: #e53e3e;
                  `} rounded-lg`}
                  onClick={() => {
                    setReportMode(true, "defaultBase");
                  }}
                >
                  Report an error
                </button>
              </div>
            </div>
            <textarea
              className="p-2 rounded-lg"
              placeholder="Base translation does not exist"
              value={textAreaText.base}
              style={{
                minWidth: "100%",
                minHeight: textAreaH,
                borderColor: "#e2e8f0"
              }}
              disabled
            />
          </div>
        )}

        {i18n.language !== fallBackLang && (
          <div className="d-flex flex-column justify-content-stretch align-items-stretch mb-3">
            <div
              className="d-flex flex-row align-items-center justify-content-evenly mb-2"
              style={{ minWidth: "100%" }}
            >
              <LazyLoadFlagImage
                className="ml-1"
                alt={i18n.language}
                src={`/assets/images/flags/${
                  i18n.language.split("-")[0] ?? "en"
                }.png`}
                style={{
                  border: "1px solid lightgray",
                  minWidth: "25px",
                  maxWidth: "25px",
                  minHeight: "15px",
                  maxHeight: "15px"
                }}
              />
              <div className="mr-2 ml-2">
                Base translation ({i18n.language})
              </div>
              <div
                className="d-flex flex-row justify-content-end"
                style={{ flex: 1 }}
              >
                <button
                  type="button"
                  className={`${css`
                    font-size: 12px !important;
                    font-weight: 600;
                    border: 1px solid #edf2f7;
                    padding: 2px 6px 2px 6px;
                    color: black;
                  `} mr-2 rounded-lg`}
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      textAreaText.baseSelected
                    );
                  }}
                >
                  Copy
                </button>
                <button
                  type="button"
                  className={`${css`
                    font-size: 12px !important;
                    font-weight: 600;
                    border: 1px solid #e53e3e;
                    background: white;
                    padding: 2px 5px 2px 5px;
                    color: #e53e3e;
                  `} rounded-lg`}
                  onClick={() => {
                    setReportMode(true, "selectedBase");
                  }}
                >
                  Report an error
                </button>
              </div>
            </div>
            <textarea
              className="p-2 rounded-lg"
              placeholder="Base translation does not exist"
              value={textAreaText.baseSelected}
              style={{
                minWidth: "100%",
                minHeight: textAreaH,
                borderColor: "#e2e8f0"
              }}
              disabled
            />
          </div>
        )}
      </div>
      <div
        className="d-flex flex-column justify-content-stretch align-items-start mr-3"
        style={{ flex: 2 }}
      >
        <div
          className="d-flex flex-column justify-content-stretch align-items-stretch"
          style={{ flex: 1, minWidth: "100%", minHeight: "100%" }}
        >
          <div
            className="d-flex flex-row align-items-center justify-content-evenly mb-2"
            style={{ minWidth: "100%" }}
          >
            <LazyLoadFlagImage
              className="ml-1"
              alt={i18n.language}
              src={`/assets/images/flags/${
                i18n.language.split("-")[0] ?? "en"
              }.png`}
              style={{
                border: "1px solid lightgray",
                minWidth: "25px",
                maxWidth: "25px",
                minHeight: "15px",
                maxHeight: "15px"
              }}
            />
            <div className="mr-2 ml-2">
              Your custom translation ({i18n.language})
            </div>
            <div
              className="d-flex flex-row justify-content-end"
              style={{ flex: 1 }}
            >
              {!isPluralDisabled && (
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="auto"
                  delay={{ show: 0, hide: 0 }}
                  overlay={
                    <Popover
                      id="button-tooltip-2"
                      style={{ width: "30vw", zIndex: "2147483650" }}
                    >
                      <Popover.Title>
                        <b>Plural Category Guide</b> <br />
                        <span style={{ fontSize: "12px" }}>
                          The table below shows the number and its plural
                          category
                        </span>
                      </Popover.Title>
                      <Popover.Content>
                        <Table bordered style={{ fontSize: "12px" }}>
                          <tbody>
                            {pluralCategories.map(category => {
                              return (
                                <tr
                                  style={{
                                    paddingBottom: "4em"
                                  }}
                                >
                                  <td
                                    style={{
                                      fontWeight: 700,
                                      width: "10%"
                                    }}
                                  >
                                    {category}
                                  </td>
                                  <td
                                    style={{
                                      width: "90%"
                                    }}
                                  >
                                    {[...Array(101).keys()].map(count => {
                                      return i18n.services.pluralResolver.getSuffix(
                                        i18n.language,
                                        count
                                      ) === `_${category}` ? (
                                        <span>{count} &nbsp;</span>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className={`${css`
                      font-size: 12px !important;
                      font-weight: 600;
                      border: 1px solid #edf2f7;
                      padding: 2px 6px 2px 6px;
                      color: black;
                      background-color: transparent;
                    `} mr-2 rounded-lg`}
                  >
                    ℹ️
                  </button>
                </OverlayTrigger>
              )}
              <select
                className="rounded-lg"
                placeholder="Pluralization Category"
                style={{
                  minWidth: "max-content",
                  height: "1.5rem",
                  borderColor: "#e2e8f0"
                }}
                value={selectedPlural}
                disabled={isPluralDisabled}
                onChange={onPluralCategorySelect}
              >
                {pluralCategories.map(cat => {
                  return (
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <textarea
            className="d-flex flex-row align-items-center justify-content-evenly mb-2 p-2 rounded-lg"
            placeholder="Enter your custom translation here..."
            value={textAreaText.custom}
            onChange={e => {
              const text = e.target.value;
              onEdit(text);
            }}
            style={{
              minWidth: "100%",
              minHeight: textAreaHRight,
              borderColor: "#e2e8f0"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Body;
