const INITIAL_STATE = {
  clientLocations: []
};

const applySetClientLocations = (state, action) => ({
  ...state,
  clientLocations: action.clientLocations
});

export const CLIENT_LOCATIONS_SET_ACTION = "CLIENT_LOCATIONS_SET";

function clientLocationsReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === CLIENT_LOCATIONS_SET_ACTION) {
    return applySetClientLocations(state, action);
  }

  return state;
}

export default clientLocationsReducer;
