import React from "react";
import { css } from "@emotion/css";
import { Button } from "react-bootstrap";

const Footer = ({
  onSave,
  onCancel,
  isSaving,
  reportModeState,
  isReportSaved,
  isSaveButtonEnabled,
  keyUpdated = false
}) => {
  return (
    <div className="d-flex flex-row justify-content-end align-items-center pb-3 pr-3 pt-3 mt-3 border border-top-1 border-light">
      <span className="mr-4">
        {isReportSaved === 1 && <b style={{ color: "green" }}>Saved</b>}
        {isReportSaved === 2 && (
          <b style={{ color: "red" }}>Not saved due to an error</b>
        )}
        {keyUpdated === true && (
          <b style={{ color: "green" }}>
            The key has been updated. It may take up to 30 minutes for the
            changes to be visible to all users.
          </b>
        )}
      </span>
      <Button
        className={`${css`
          background-color: white;
          border-color: #e2e8f0;
          color: black;
          border-radius: 4px;
          &:hover {
            border-color: #e2e8f0;
            color: black;
            background-color: #e2e8f0;
          }
          &:focus {
            border-color: #e2e8f0;
            color: black;
            background-color: #e2e8f0;
          }
        `} mr-2`}
        size="sm"
        onClick={onCancel}
      >
        {reportModeState ? "Back" : "Close"}
      </Button>
      <Button
        className={`${css`
          background-color: #25aae1;
          border-color: #25aae1;
          &:hover {
            color: black;
            border-color: #e2e8f0;
            background-color: #e2e8f0;
          }
          &:focus {
            border-color: #e2e8f0;
            color: black;
            background-color: #e2e8f0;
          }
          &:disabled {
            border-color: #e2e8f0;
            color: black;
            background-color: #e2e8f0;
          }
        `} pr-3 pl-3`}
        size="sm"
        disabled={!isSaveButtonEnabled}
        onClick={onSave}
      >
        {isSaving ? "Saving" : "Save"}
      </Button>
    </div>
  );
};

export default Footer;
