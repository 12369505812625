import axios from "axios";
import { GCF } from "../../constants/dbQuery";

export const setClientAndRole = async user => {
  const bearerToken = await user.getIdToken();
  return axios({
    method: "get",
    url: `${GCF.BASE_URL}/${GCF.ROUTE.AUTH.BASE_URL}/${GCF.ROUTE.AUTH.LOGIN}`,
    headers: {
      Authorization: `Bearer ${bearerToken}`
    },
    timeout: 30000
  });
};

export const setCustomClaims = async (db, authUser) => {
  const token = await authUser.getIdToken(true);
  await axios.post(
    `${GCF.BASE_URL}/${GCF.ROUTE.AUTH.BASE_URL}/${GCF.ROUTE.AUTH.USER.BASE_URL}/${GCF.ROUTE.AUTH.USER.SET_CUSTOM_CLAIMS}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 30000
    }
  );

  return true;
};
