/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITriggersState } from "@rooster/types";

interface IInitialState extends ITriggersState {
  error: any;
}

const initialState: IInitialState = {
  data: [],
  error: null,
  loading: false,
  templates: [],
  audience: [],
  senders: [],
  templatesLoading: true,
  audienceLoading: true
};

export const triggerSlice = createSlice({
  name: "triggersState",
  initialState,
  reducers: {
    saveTriggers: (state, action: PayloadAction<any>) => {
      state.data = action.payload || [];
    },
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    loadTemplates: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.templatesLoading = false;
      state.templates = action.payload;
    },
    loadAudience: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.audience = action.payload;
      state.audienceLoading = false;
    },
    loadSenders: (state, action: PayloadAction<any>) => {
      state.senders = action.payload;
    }
  }
});

export const triggerActions = triggerSlice.actions;

export const triggerReducer = triggerSlice.reducer;
