export const INITIAL_STATE = {
  interviewOptions: {
    sendAsEmail: "",
    sendAsCalendar: "",
    sendOnlyModifiedInterviews: false,
    sendInviteToCandidate: true,
    delay: 0,
    private: true,
    interviewer: {
      emails: false,
      events: true,
      sms: false
    },
    sender: {
      email: {
        email: "",
        name: ""
      },
      event: {
        email: "",
        name: ""
      }
    },
    reminders: null,
    automation: null
  }
};

export const SET_INTERVIEW_OPTIONS_ACTION = "SET_INTERVIEW_OPTIONS";
const applySetInterviewOptions = (state, action) => ({
  interviewOptions: { ...state.interviewOptions, ...action.interviewOptions }
});

export const RESET_INTERVIEW_OPTIONS_ACTION = "RESET_INTERVIEW_OPTIONS";
const resetInterviewOptions = () => {
  return { ...INITIAL_STATE };
};

function interviewOptionsReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === SET_INTERVIEW_OPTIONS_ACTION) {
    return applySetInterviewOptions(state, action);
  }
  if (action.type === RESET_INTERVIEW_OPTIONS_ACTION) {
    return resetInterviewOptions();
  }
  return state;
}

export default interviewOptionsReducer;
