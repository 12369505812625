const INITIAL_STATE = {
  reminders: null,
  interviewStages: null,
  triggers: null
};

const applyClientReminders = (state, action) => ({
  ...state,
  reminders: action.reminders
});

const applyClientInterviewStages = (state, action) => ({
  ...state,
  interviewStages: action.interviewStages
});

const applyClientTriggers = (state, action) => ({
  ...state,
  triggers: action.triggers
});

export const SET_CLIENT_REMINDERS_ACTION = "SET_CLIENT_REMINDERS";
export const SET_CLIENT_INTERVIEW_STAGES_ACTION = "SET_CLIENT_INTERVIEW_STAGES";
export const SET_CLIENT_TRIGGERS_ACTION = "SET_CLIENT_TRIGGERS";

export const actionTypes = {
  SET_CLIENT_REMINDERS_ACTION: "SET_CLIENT_REMINDERS",
  SET_CLIENT_AUTOMATION_RULES: "SET_CLIENT_AUTOMATION_RULES"
};

function additionalClientPreferencesReducer(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case actionTypes.SET_CLIENT_REMINDERS_ACTION:
      return applyClientReminders(state, action);
    case actionTypes.SET_CLIENT_AUTOMATION_RULES:
      return {
        ...state,
        automation: action.payload
      };
    case SET_CLIENT_INTERVIEW_STAGES_ACTION:
      return applyClientInterviewStages(state, action);
    case SET_CLIENT_TRIGGERS_ACTION:
      return applyClientTriggers(state, action);
    default:
      return state;
  }
}

export default additionalClientPreferencesReducer;
