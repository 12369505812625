const INITIAL_STATE = {
  clientDocData: null
};

const applySetClientDocData = (state, action) => ({
  ...state,
  clientDocData: action.clientDocData
});

export const CLIENT_DOC_DATA_SET = "CLIENT_DOC_DATA_SET";
function clientDocDataReducer(state, action) {
  if (!state) {
    return INITIAL_STATE;
  }
  if (action.type === "CLIENT_DOC_DATA_SET") {
    return applySetClientDocData(state, action);
  }
  return state;
}

export default clientDocDataReducer;
