import axios, { AxiosInstance } from "axios";
import { firebase } from "../index";

export const getAxiosInstance = async (
  timeoutMs?: number
): Promise<AxiosInstance> => {
  const { auth } = firebase;
  const bearerToken = await auth?.currentUser?.getIdToken();

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };
  const timeout = timeoutMs ?? 30000;

  const instance = axios.create();

  instance.interceptors.request.use(config => {
    return {
      ...config,
      headers: { ...headers },
      timeout
    };
  });

  return instance;
};
