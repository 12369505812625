export const ALERTS = "alerts";
export const APPLICATIONS = "applications";
export const AUTOMATION = "automation";
export const AVAILABILITY = "availability";
export const CALENDARS = "calendars";
export const CLIENTS = "clients";
export const COMPETENCIES = "competencies";
export const CONFERENCING = "conferencing";
export const CONFIG = "config";
export const DEFAULT = "default";
export const DOMAINS = "domains";
export const EMAILGROUPS = "email-groups";
export const SCHEDULERSROLES = "schedulers-roles";
export const JOBDESCRIPTIONS = "job-descriptions";
export const EMAILTEMPLATES = "email-templates";
export const GRANTS = "grants";
export const HISTORY = "history";
export const INSIGHTS = "insights";
export const INTERVIEW = "interview";
export const INTERVIEW_STAGES = "interview-stages";
export const INTERVIEWS = "interviews";
export const INTERVIEWTYPES = "interview-types";
export const LOADS = "loads";
export const LOCATIONS = "locations";
export const MAPPINGS = "mappings";
export const MESSAGES = "messages";
export const PREFERENCES = "preferences";
export const QUESTIONNAIRES = "questionnaires";
export const REASONS = "reasons";
export const REMINDERS = "reminders";
export const ROLES = "roles";
export const ROOMS = "rooms";
export const ROOMSLOCATIONS = "rooms-locations";
export const SAMLTOKENS = "saml-tokens";
export const SKILLS = "skills";
export const SMSSTEPS = "sms-steps";
export const WHATSAPPSTEPS = "whatsapp-steps";
export const SYSTEM = "system";
export const TEMPLATES = "templates";
export const TRIGGERS = "triggers";
export const USERS = "users";
export const WORKDAY_DATA = "workday-data";
export const WORKERS = "workers";
export const WORKERS_ATTRIBUTES = "workers-attributes";
export const CANDIDATES = "candidates";
export const PENDING_INVITES = "pending-invites";
export const SCHEDULING_GROUPS = "scheduling-groups";
export const AUTOBOOK_PROFILES = "autobook-profiles";
export const CUSTOM_FIELDS = "custom-fields";
