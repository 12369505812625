import { gql } from "../../../@generated/gql";

export const GET_TAGS = gql(/* GraphQL */ `
  query GetTags($filter: TagsFilterDto!) {
    tags(filter: $filter) {
      count
      tags {
        id
        clientId
        name
        type
        archived
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`);

export const CREATE_TAG = gql(/* GraphQL */ `
  mutation CreateTag($input: TagCreateInput!) {
    createTag(input: $input) {
      id
    }
  }
`);

export const UPDATE_TAG = gql(/* GraphQL */ `
  mutation UpdateTag($id: String!, $data: TagUpdateInput!) {
    updateTag(id: $id, data: $data) {
      id
    }
  }
`);

export const DELETE_TAG = gql(/* GraphQL */ `
  mutation DeleteTag($id: String!) {
    deleteTag(id: $id) {
      id
    }
  }
`);

export const DELETE_MANY_TAGS = gql(/* GraphQL */ `
  mutation DeleteManyTags($data: DeleteTagsDto!) {
    deleteManyTags(data: $data) {
      count
    }
  }
`);

export const ARCHIVE_MANY_TAGS = gql(/* GraphQL */ `
  mutation ArchiveManyTags($data: ArchiveTagsDto!) {
    archiveManyTags(data: $data) {
      count
    }
  }
`);
