const INITIAL_STATE = {
  interviewers: null
};

const applySetInterviewers = (state, action) => ({
  ...state,
  interviewers: action.interviewers
});

function interviewersReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "INTERVIEWERS_SET") {
    return applySetInterviewers(state, action);
  } else {
    return state;
  }
}

export default interviewersReducer;
