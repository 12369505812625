import { ISanitizedWorker } from "@rooster/types";
import { uniqBy } from "lodash";
import React, { FC, useState } from "react";

export const WorkersCacheContext = React.createContext({
  workers: [] as ISanitizedWorker[],
  addWorkers: (workers: ISanitizedWorker[]): void => {}
});

export const WorkersCacheProvider: FC = ({ children }) => {
  const [workersState, setWorkersState] = useState<ISanitizedWorker[]>([]);

  const addWorkers = (workers: ISanitizedWorker[]): void => {
    setWorkersState(existingWorkers =>
      uniqBy([...workers, ...existingWorkers], ({ key }) => key)
    );
  };

  return (
    <WorkersCacheContext.Provider
      value={{
        workers: workersState,
        addWorkers
      }}
    >
      {children}
    </WorkersCacheContext.Provider>
  );
};
