import React, { useEffect, useState, useRef } from "react";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";
import Report from "./components/Report";
import Instructions from "./components/Instructions";
import { GCF } from "../../../constants/dbQuery";
import { loadPath, fallbackLanguage } from "../../config";

const ContainerStyle = css`
  min-width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483649 !important;
  border-radius: 4px;
`;

export default function InContextEditor({ editModeHandler, props }) {
  const { i18n } = useTranslation();
  const [isOpenState, setIsOpen] = useState(false);
  const [isMinimizedState, setIsMinimizedState] = useState(false);
  const [targetLangText, setTargetLangText] = useState("");
  const [selectedKeyState, setKeyState] = useState("");
  const origSelectedKeyRef = useRef("");
  const [savingState, setSavingState] = useState(false);
  const [selectedPluralState, setSelectedPluralState] = useState("one");
  const [reportModeState, setReportModeState] = useState(false);
  const [isReportSavedState, setReportSavedState] = useState(0);
  const [reportState, setReportState] = useState({
    translationKey: null,
    locale: null,
    comment: null,
    correction: null
  });
  const [reportTypeState, setReportTypeState] = useState("defaultBase");
  const [isPluralDisabledState, setPluralDisabledState] = useState(true);
  const [textAreaState, setTextAreaState] = useState({
    base: null,
    baseSelected: null,
    custom: null
  });
  const [isSaveButtonEnabledState, setSaveButtonEnabledState] = useState(true);
  const [keyUpdatedState, setKeyUpdatedState] = useState(false);

  const getPluralCategoryFromCount = count => {
    return i18n.services.pluralResolver
      .getSuffix(i18n.language, parseInt(count, 10))
      .replace("_", "");
  };

  const setReportMode = (value, type = "defaultBase") => {
    setReportTypeState(type);
    setReportModeState(value);
  };

  const setReport = (comment, correction) => {
    setReportState({
      translationKey: selectedKeyState,
      locale:
        reportTypeState === "defaultBase" ? fallbackLanguage : i18n.language,
      comment,
      correction
    });
  };

  const updateTextAreaTextState = () => {
    i18n.changeLanguage(i18n.language);
    setTextAreaState({
      base:
        i18n.getDataByLanguage(fallbackLanguage)?.base?.[selectedKeyState] ??
        "",
      baseSelected:
        i18n.getDataByLanguage(i18n.language)?.base?.[selectedKeyState] ?? "",
      custom:
        i18n.getDataByLanguage(i18n.language)?.translation?.[
          selectedKeyState
        ] ?? ""
    });
  };

  const onSave = async () => {
    setSavingState(true);
    const bearerToken = await props.firebase.auth.currentUser.getIdToken();
    const headers = {
      Authorization: `Bearer ${bearerToken}`
    };
    const configuration = {
      headers
    };

    try {
      if (reportModeState) {
        await axios.post(
          `${GCF.BASE_URL}/i18n/report`,
          reportState,
          configuration
        );
        setReportSavedState(1);
      } else {
        const requestBodyChange = {
          language: i18n.language,
          text: targetLangText,
          origKey: origSelectedKeyRef.current,
          pluralCategory: selectedPluralState
        };
        setKeyUpdatedState(false);
        await axios.post(
          `${GCF.BASE_URL}/i18n/updateKey`,
          requestBodyChange,
          configuration
        );
        i18n.addResourceBundle(
          i18n.language,
          "translation",
          { [selectedKeyState]: targetLangText },
          true,
          true
        );
        i18n.changeLanguage(i18n.language);
        setKeyUpdatedState(true);
      }

      setSavingState(false);
    } catch (e) {
      if (reportModeState) {
        setReportSavedState(2);
      }
      setKeyUpdatedState(false);
      setSavingState(false);
    }
  };

  const onCancel = () => {
    if (!reportModeState) {
      setTargetLangText("");
      setKeyState("");
    }
    setReportMode(false);
    setReportSavedState(0);
  };

  const loadBaseLang = async lng => {
    const baseLngBundle = i18n.hasResourceBundle(lng, "base");

    if (!baseLngBundle) {
      const { data } = await axios.get(loadPath.replace("{{lng}}", lng));
      // eslint-disable-next-line require-atomic-updates
      i18n.store.data[lng] = { ...i18n.store.data[lng], base: data };
      return data;
    }
    return baseLngBundle;
  };

  const toggleView = () => {
    setIsMinimizedState(!isMinimizedState);
  };

  const checkIfPluralExist = key => {
    const exists = i18n.exists(
      `${key ?? origSelectedKeyRef.current ?? selectedKeyState}_one`
    );
    if (exists) {
      setPluralDisabledState(false);
      return true;
    }
    setPluralDisabledState(true);
    return false;
  };

  const getPluralCategories = locale => {
    return new Intl.PluralRules(locale).resolvedOptions().pluralCategories;
  };

  useEffect(() => {
    window.addEventListener("contextmenu", ev => {
      const el = ev.target.getAttribute("id");
      const dataCount = ev.target.getAttribute("data-count");
      const dataCountAttr = dataCount === "0" ? "0" : dataCount ?? "1";

      const pluralCategory = getPluralCategoryFromCount(dataCountAttr);
      const keyModifier = checkIfPluralExist(el) ? `_${pluralCategory}` : "";

      setReportMode(false);
      if (
        i18n.getDataByLanguage(fallbackLanguage)?.base?.[
          `${el}${keyModifier}`
        ] !== undefined ||
        keyModifier
      ) {
        origSelectedKeyRef.current = el;
        setKeyState(el + keyModifier);
        if (keyModifier) {
          setSelectedPluralState(pluralCategory);
        }
      } else {
        setKeyState("");
      }
    });
  }, [isOpenState]);

  useEffect(() => {
    setReportSavedState(0);
    if (selectedKeyState === "") {
      origSelectedKeyRef.current = null;
      setIsOpen(false);
    }
    if (selectedKeyState !== "") {
      setIsOpen(true);
    }
    setTargetLangText("");
  }, [selectedKeyState]);

  useEffect(() => {
    if (origSelectedKeyRef.current !== null) {
      setKeyState(`${origSelectedKeyRef.current}_${selectedPluralState}`);
    }
  }, [selectedPluralState]);

  useEffect(() => {
    const baseLangLoadPromises = [loadBaseLang(fallbackLanguage)];

    if (i18n.language !== fallbackLanguage) {
      baseLangLoadPromises.push(loadBaseLang(i18n.language));
    }

    Promise.all(baseLangLoadPromises).then(() => {
      updateTextAreaTextState();
      i18n.changeLanguage(i18n.language);
    });
  }, [i18n.language]);

  useEffect(() => {
    updateTextAreaTextState();
  }, [i18n.language, selectedKeyState]);

  useEffect(() => {
    if (reportModeState) {
      if (reportState.comment || reportState.correction) {
        setSaveButtonEnabledState(true);
      } else {
        setSaveButtonEnabledState(false);
      }
    } else if (
      i18n.getDataByLanguage(i18n.language)?.translation?.[selectedKeyState] !==
      textAreaState.custom
    ) {
      setSaveButtonEnabledState(true);
    } else {
      setSaveButtonEnabledState(false);
    }
  }, [reportModeState, reportState, textAreaState, keyUpdatedState]);

  return (
    <div className={ContainerStyle}>
      <Header
        isMinimized={isMinimizedState}
        selectedKey={selectedKeyState}
        toggleView={toggleView}
        editModeHandler={editModeHandler}
      />
      {!isMinimizedState && (
        <span>
          {isOpenState && (
            <div>
              {reportModeState ? (
                <Report
                  setReport={setReport}
                  baseText={
                    i18n.getDataByLanguage(
                      reportTypeState === "defaultBase"
                        ? fallbackLanguage
                        : i18n.language
                    )?.base?.[selectedKeyState] ?? ""
                  }
                />
              ) : (
                <Body
                  selectedPlural={selectedPluralState}
                  selectedKey={selectedKeyState}
                  isPluralDisabled={isPluralDisabledState}
                  textAreaText={textAreaState}
                  onEdit={text => {
                    setTextAreaState({ ...textAreaState, custom: text });
                    setTargetLangText(text);
                  }}
                  onPluralCategorySelect={e => {
                    setSelectedPluralState(e.target.value);
                  }}
                  i18n={i18n}
                  setReportMode={setReportMode}
                  pluralCategories={getPluralCategories(i18n.language)}
                  fallBackLang={fallbackLanguage}
                />
              )}
              <Footer
                onSave={onSave}
                onCancel={onCancel}
                isSaving={savingState}
                reportModeState={reportModeState}
                isReportSaved={isReportSavedState}
                isSaveButtonEnabled={!savingState && isSaveButtonEnabledState}
                keyUpdated={keyUpdatedState}
              />
            </div>
          )}

          {!isOpenState && (
            <Instructions customerId={i18n?.options?.customerId} />
          )}
        </span>
      )}
    </div>
  );
}
