const INITIAL_STATE = {
  clientUsers: [],
  queriedTags: [],
  imageUrls: {},
  imageCache: {}
};

const applySetClientUsers = (state, action) => ({
  ...state,
  clientUsers: action.clientUsers
});

const applySetQueriedTags = (state, action) => ({
  ...state,
  queriedTags: {
    ...state.queriedTags,
    [action.queriedTag]: action.userWids
  }
});

const applySetFailedQueriedTags = (state, action) => ({
  ...state,
  failedQueriedTags: {
    ...state.failedQueriedTags,
    [action.failedTag]: action.ts
  }
});

const applySetDepartments = (state, action) => ({
  ...state,
  departments: action.departments
});

const applySetTitles = (state, action) => ({
  ...state,
  titles: action.titles
});

const applySetLoadedDepartments = (state, action) => ({
  ...state,
  loadedDepartments: action.departments
});

const applySetLoadedTitles = (state, action) => ({
  ...state,
  loadedTitles: action.titles
});

const applySetImageCache = (state, action) => ({
  ...state,
  imageCache: {
    ...state.imageCache,
    [action.imageUrl]: action.imageLoadState
  }
});

const applySetImageUrls = (state, action) => ({
  ...state,
  imageUrls: {
    ...state.imageUrls,
    [action.imagePath]: action.imageUrlFetched
  }
});

export const CLIENT_USERS_SET_ACTION = "CLIENT_USERS_SET";
export const QUERIED_TAGS_SET_ACTION = "QUERIED_TAGS_SET";
export const FAILED_QUERIED_TAGS_SET_ACTION = "FAILED_QUERIED_TAGS_SET";
export const DEPARTMENTS_SET_ACTION = "DEPARTMENTS_SET";
export const TITLES_SET_ACTION = "TITLES_SET";
export const LOADED_DEPARTMENTS_SET_ACTION = "LOADED_DEPARTMENTS_SET";
export const LOADED_TITLES_SET_ACTION = "LOADED_TITLES_SET";
export const IMAGE_CACHE_SET_ACTION = "IMAGE_CACHE_SET";
export const IMAGE_URL_SET_ACTION = "IMAGE_URL_SET";

const clientUsersReducer = (state, action) => {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case CLIENT_USERS_SET_ACTION:
      return applySetClientUsers(state, action);

    case QUERIED_TAGS_SET_ACTION:
      return applySetQueriedTags(state, action);

    case FAILED_QUERIED_TAGS_SET_ACTION:
      return applySetFailedQueriedTags(state, action);

    case DEPARTMENTS_SET_ACTION:
      return applySetDepartments(state, action);

    case TITLES_SET_ACTION:
      return applySetTitles(state, action);

    case LOADED_DEPARTMENTS_SET_ACTION:
      return applySetLoadedDepartments(state, action);

    case LOADED_TITLES_SET_ACTION:
      return applySetLoadedTitles(state, action);

    case IMAGE_CACHE_SET_ACTION:
      return applySetImageCache(state, action);

    case IMAGE_URL_SET_ACTION:
      return applySetImageUrls(state, action);

    default:
      return state;
  }
};

export default clientUsersReducer;
