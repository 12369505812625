/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlertOptions } from "../../hooks/alert/options";

interface IInitialOptions extends IAlertOptions {
  show: boolean;
}

const initialState: IInitialOptions = {
  show: false,
  customComponent: null,
  title: null,
  text: null,
  icon: null,
  buttons: [],
  className: null,
  closeOnClickOutside: true,
  closeOnEsc: true,
  dangerMode: false,
  timer: null,
  callback: null
};

export const alertSlice = createSlice({
  name: "alertPopup",
  initialState,
  reducers: {
    show: state => {
      state.show = true;
    },
    hide: state => {
      state.show = false;
    },
    setPopupData: (state, action: PayloadAction<IAlertOptions>) => {
      const {
        title,
        text,
        buttons,
        icon,
        className,
        closeOnEsc,
        closeOnClickOutside,
        dangerMode,
        timer,
        callback,
        customComponent
      } = action.payload;

      state.title = title;
      state.text = text;
      state.customComponent = customComponent;
      state.buttons = buttons;
      state.icon = icon;
      state.className = className;
      state.closeOnClickOutside = closeOnClickOutside;
      state.closeOnEsc = closeOnEsc;
      state.dangerMode = dangerMode;
      state.timer = timer;
      state.callback = callback;
    }
  }
});

export const { show, hide, setPopupData } = alertSlice.actions;

export type alertPopupStateType = typeof initialState;

export default alertSlice.reducer;
