const INITIAL_STATE = {
  interviewNotifications: null
};

const applySetInterviewNotifications = (state, action) => ({
  ...state,
  interviewNotifications: action.interviewNotifications
});

function interviewNotificationsReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === "INTERVIEW_NOTIFICATIONS_SET") {
    return applySetInterviewNotifications(state, action);
  } else {
    return state;
  }
}

export default interviewNotificationsReducer;
