import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  formatDate,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventUpdatedEvent,
  MbscResource,
  momentTimezone,
  setOptions,
  Toast
} from "@mobiscroll/react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Pagination,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "./interviewers.availability.css";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InterviewersFilterDto, Tag } from "../../../@generated/graphql";
import { useQuery } from "@apollo/client";
import { useQuery as reactUseQuery } from "react-query";
import { GET_INTERVIEWERS } from "../../Interviewers/services/interviewers.gql";
import { useGetAvailabilityForInterviewers } from "./hooks";
import { AutobookViewApiPayload, IWorkerSelect } from "@rooster/types";
import { useSelector } from "react-redux";
import { getEmailDomain } from "@rooster/utils";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce, omit, uniqBy } from "lodash";
import TagsSelect from "../../Tags/tags.select.component";
import { get } from "../../../utilities/restClient";
import { RoosterCalendarApplicationDetails } from "../../../contexts/events/types";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

momentTimezone.moment = moment;

setOptions({
  theme: "ios",
  themeVariant: "light"
});

const InterviewersAvailability = (props: {
  duration: number;
  startDate: Date;
  interviewTimeZone: string;
  selected: IWorkerSelect[];
  applicationDetails?: RoosterCalendarApplicationDetails;
  onCancel: () => void;
  onApply: (
    event: {
      startDate: Date;
      startTimeInMins: number;
      duration: number;
    },
    selected: IWorkerSelect[]
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const {
    duration = 60,
    startDate = new Date(),
    interviewTimeZone = moment.tz.guess(),
    selected = [],
    applicationDetails,
    onCancel,
    onApply
  } = props ?? {};
  const [selectedEvents, setSelectedEvents] = useState<MbscCalendarEvent[]>([]);
  const eventDuration = duration < 15 ? 15 : duration;
  const [isToastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );

  const [interviewerFilterParams, setInterviewerFilterParams] = useLocalStorage<
    InterviewersFilterDto & {
      selectedTags: Tag[];
      considerCandidateAvailability: boolean;
    }
  >(
    `interviewers-availability-selection-filter`,
    {
      searchTerm: "",
      tags: [],
      departments: [],
      skipEmptyEmails: true,
      selectedTags: [],
      considerCandidateAvailability: true
    },
    true
  );
  const [page, setPage] = useState(1);
  const [timezones, setTimezones] = useState<string[]>([interviewTimeZone]);
  const {
    loading: interviewersLoading,
    data: interviewersResponse,
    refetch: interviewersRefetch
  } = useQuery(GET_INTERVIEWERS, {
    variables: {
      filter: {
        ...omit(interviewerFilterParams, [
          "selectedTags",
          "considerCandidateAvailability"
        ]),
        searchTerm:
          interviewerFilterParams?.searchTerm &&
          interviewerFilterParams?.searchTerm?.length < 3
            ? ""
            : interviewerFilterParams?.searchTerm,
        timezones: timezones,
        page,
        perPage: selected?.length > 13 ? 25 - selected?.length : 12
      }
    }
  });

  const clientDocData = useSelector(
    (state: any) => state?.clientDocDataState?.clientDocData
  );
  const showTentativeAsBusy =
    clientDocData?.preferences?.autobook?.showTentativeAsBusy ?? true;
  const domains =
    useSelector((state: any) => state.domainsReducer.domains) ?? {};

  const [availabilityFilterParams, setAvailabilityFilterParams] = useState<{
    client: string;
    queryWindow: AutobookViewApiPayload["queryWindows"][0];
    requesterEmail: AutobookViewApiPayload["requesterEmail"];
    queryResources: AutobookViewApiPayload["queryResources"];
    tzid: AutobookViewApiPayload["responseFormat"]["tzid"];
    page: number;
    duration: number;
    showTentativeAsBusy: boolean;
  } | null>(null);

  const startDateString = moment(startDate).isBefore(moment())
    ? moment().format("YYYY-MM-DD")
    : moment(startDate).format("YYYY-MM-DD");
  const startDateInTimezone = moment
    .tz(startDateString, interviewTimeZone)
    .startOf("day");
  const endTimeInTimezone = startDateInTimezone.clone().add(2, "weeks");
  const queryWindow = {
    timeMin: startDateInTimezone.toISOString(),
    timeMax: endTimeInTimezone.toISOString()
  };
  const { data: availabilityData, isFetching: availabilityLoading } =
    useGetAvailabilityForInterviewers(availabilityFilterParams);

  const { data: departmentsResponse } = reactUseQuery(
    ["CLIENT_DEPARTMENTS"],
    async () => {
      return get(`${import.meta.env.REACT_APP_URL_API}/tenants/departments`);
    }
  );

  const { data: availabilityResponse } = reactUseQuery(
    ["CANDIDATE_AVAILABILITY"],
    async () => {
      return get(
        `${import.meta.env.REACT_APP_URL_API}/applications/availability/${
          applicationDetails?.jobApplicationId
        }`
      );
    },
    {
      enabled: !!applicationDetails?.jobApplicationId
    }
  );
  const [invalidResources, setInvalidResources] = useState<
    {
      type: string;
      value: string;
      provider: string;
    }[]
  >([]);

  useEffect(() => {
    if (availabilityResponse?.data?.availability?.length > 0) {
      const availableSlots: any = [];
      const clientTimeZone =
        availabilityResponse?.data?.clientTimeZone ?? interviewTimeZone;
      const allSlots: any = [];

      const availabilityProvided =
        availabilityResponse?.data?.availability?.filter((slot: any) => {
          const slotEnd = moment(slot.end).tz(clientTimeZone);
          return slotEnd.isAfter(moment().tz(clientTimeZone));
        });
      if (availabilityProvided?.length > 0) {
        setInterviewerFilterParams({
          ...interviewerFilterParams,
          considerCandidateAvailability: true
        });
        let current = startDateInTimezone.clone();

        while (current.isBefore(endTimeInTimezone)) {
          const slotStart = current.clone();
          const slotEnd = current.clone().add(eventDuration, "minutes");

          const overlappingSlot = availabilityProvided?.find((slot: any) => {
            const availableStart = moment(slot.start);
            const availableEnd = moment(slot.end);
            return (
              (slotStart.isSameOrAfter(availableStart) &&
                slotStart.isBefore(availableEnd)) ||
              (slotStart.isBefore(availableStart) &&
                slotEnd.isAfter(availableStart))
            );
          });

          const createSlot = (
            start: moment.Moment,
            end: moment.Moment,
            available: boolean
          ) => ({
            start: start.toDate(),
            end: end.toDate(),
            title: start.tz(clientTimeZone).format("hh:mm A"),
            background: available ? "#c9e8d1" : "#ffbaba4d",
            available,
            resource: applicationDetails?.candidateEmail
          });

          if (overlappingSlot) {
            const availableStart = moment(overlappingSlot.start);
            const availableEnd = moment(overlappingSlot.end);

            const partialStart = slotStart.isBefore(availableStart)
              ? availableStart
              : slotStart;
            const partialEnd = slotEnd.isAfter(availableEnd)
              ? availableEnd
              : slotEnd;

            availableSlots.push(createSlot(partialStart, partialEnd, true));

            if (partialStart.isAfter(slotStart)) {
              allSlots.push(createSlot(slotStart, partialStart, false));
            }

            if (partialEnd.isBefore(slotEnd)) {
              allSlots.push(createSlot(partialEnd, slotEnd, false));
            }
          } else {
            allSlots.push(createSlot(slotStart, slotEnd, false));
          }

          current.add(eventDuration, "minutes");
        }

        const candidateResource = {
          id: applicationDetails?.candidateEmail ?? "",
          name: applicationDetails?.candidateName,
          email: applicationDetails?.candidateEmail,
          timezone: clientTimeZone,
          utcOffset: `${clientTimeZone}`,
          organizer: false,
          candidate: true,
          selected: true
        };
        setFixedResourceDetails({
          ...fixedResourceDetails,
          [candidateResource?.id]: {
            colors: [...availableSlots, ...allSlots]
          }
        });
        if (!fixedResources.some((fr) => fr.id === candidateResource.id)) {
          setFixedResources([candidateResource, ...fixedResources]);
        }
      } else {
        setInterviewerFilterParams({
          ...interviewerFilterParams,
          considerCandidateAvailability: false
        });
      }
    } else {
      setInterviewerFilterParams({
        ...interviewerFilterParams,
        considerCandidateAvailability: false
      });
    }
  }, [availabilityResponse]);

  const invalidInterviewerResources: MbscResource[] = useMemo(() => {
    const interviewerList =
      interviewersResponse?.interviewers?.interviewers ?? [];
    const invalidList: MbscResource[] = [];
    if (
      invalidResources?.length > 0 &&
      interviewerList?.length > 0 &&
      clientDocData
    ) {
      invalidResources?.forEach((resource) => {
        const interviewer = interviewerList.find(
          (i) => i.email === resource.value
        );
        const timezone =
          interviewer?.preferences?.availability?.timezone ??
          clientDocData?.workday?.timeFormat ??
          "utc";
        if (interviewer) {
          invalidList.push({
            id: interviewer?.email,
            name: interviewer?.name,
            email: interviewer?.email,
            organizer: false,
            invalid: true,
            worker: interviewer,
            timezone,
            utcOffset: timezone
          });
        }
      });
    }
    return invalidList;
  }, [
    invalidResources,
    interviewersResponse?.interviewers?.interviewers,
    clientDocData
  ]);

  useEffect(() => {
    if (
      ((interviewersResponse &&
        interviewersResponse?.interviewers?.interviewers?.length > 0) ||
        selected?.length > 0) &&
      authUser?.client &&
      Object.keys(domains).length > 0
    ) {
      const queryResources =
        interviewersResponse?.interviewers?.interviewers?.map(
          (interviewer) => ({
            type: "interviewer",
            value: interviewer.email,
            provider: domains?.[getEmailDomain(interviewer?.email)]?.provider
          })
        ) ?? [];
      if (selected.length > 0) {
        queryResources.push(
          ...selected.map((s) => ({
            type: "interviewer",
            value: s.email,
            provider: domains?.[getEmailDomain(s.email)]?.provider
          }))
        );
      }
      const resourcesWithNoProviders = queryResources.filter(
        (r) => !r.provider && r.value
      );
      if (resourcesWithNoProviders?.length > 0) {
        setInvalidResources(resourcesWithNoProviders);
      } else {
        setInvalidResources([]);
      }
      if (queryResources?.filter((r) => !!r.provider)?.length > 0) {
        setAvailabilityFilterParams({
          ...(availabilityFilterParams ?? {}),
          client: authUser.client,
          queryWindow,
          duration: eventDuration,
          queryResources: uniqBy(
            queryResources?.filter((r) => !!r.provider),
            "value"
          ),
          showTentativeAsBusy,
          requesterEmail: authUser.email,
          tzid: interviewTimeZone,
          page: 1
        });
      }
    }
  }, [interviewersResponse?.interviewers, authUser, domains]);

  const resources: MbscResource[] = useMemo(() => {
    const interviewers = interviewersResponse?.interviewers?.interviewers;
    const resourceList: MbscResource[] = [];
    if (availabilityData && interviewers) {
      interviewers?.forEach((interviewer) => {
        if (!invalidResources?.some((r) => r.value === interviewer.email)) {
          const availability = availabilityData.queryWindowsResult.find(
            (x) => x.participant === interviewer.email
          );
          const timezone =
            availability?.interviewerPreference?.availability?.timezone ??
            "utc";
          resourceList.push({
            id: interviewer.email,
            name: interviewer.name,
            email: interviewer.email,
            timezone,
            utcOffset: `${timezone}`,
            organizer: false,
            worker: interviewer
          });
        }
      });
    }
    return resourceList;
  }, [availabilityData, interviewersResponse?.interviewers, invalidResources]);

  const [eligibleResources, setEligibleResources] =
    useState<MbscResource[]>(resources);
  const [fixedNr, setFixedNr] = useState<number>(0);
  const [fixedResources, setFixedResources] = useState<MbscResource[]>([]);
  const [fixedResourceDetails, setFixedResourceDetails] = useState<{
    [key: string]: {
      colors: MbscCalendarColor[];
    };
  }>({});

  const myView = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: "day",
        timeCellStep: eventDuration,
        timeLabelStep: eventDuration
      }
    }),
    []
  );

  const details = useMemo(() => {
    const colors: MbscCalendarColor[] = [];

    availabilityData?.queryWindowsResult?.forEach((queryWindow) => {
      queryWindow.availabilityView?.forEach((view) => {
        const date = moment(view.date).clone().tz(interviewTimeZone);

        const startOfDay = moment(date).startOf("day");
        view.availability?.forEach((slot) => {
          const slotStart = startOfDay.clone().add(slot.hour, "hours");
          slotStart.add(slot.minute, "minutes");
          const slotEnd = slotStart.clone().add(eventDuration, "minutes");

          const slotStartUTC = slotStart.clone().utc();
          const participantTimezone =
            queryWindow.interviewerPreference?.availability?.timezone ?? "utc";

          const slotStartParticipantTime = slotStartUTC
            .clone()
            .tz(participantTimezone)
            .format("hh:mm A");

          colors.push({
            start: slotStart.toDate(),
            end: slotEnd.toDate(),
            title: slotStartParticipantTime,
            available: slot.available && !slot.outsideOfWorkingHours,
            background: slot.available
              ? "#c9e8d1"
              : slot.outsideOfWorkingHours
                ? "#f7f7bb4d"
                : "#ffbaba4d",
            resource: queryWindow.participant
          });
        });
      });
    });

    return { colors };
  }, [resources, availabilityData]);

  const fixedResourcesColors = useMemo(() => {
    const colors: MbscCalendarColor[] = [];
    fixedResources.forEach((resource) => {
      if (!resources?.find((r) => r.id === resource.id)) {
        const resourceDetails = fixedResourceDetails[resource.id];
        if (resourceDetails) {
          colors.push(...(resourceDetails?.colors ?? []));
        }
      }
    });
    return colors;
  }, [fixedResources, fixedResourceDetails, resources]);

  const myScheduleEvent = useCallback((data: MbscCalendarEventData) => {
    const start = (data.startDate as MyDate).clone();
    const end = (data.endDate as MyDate).clone();

    start.setTimezone(data.currentResource!.timezone);
    end.setTimezone(data.currentResource!.timezone);

    return (
      <div
        className="md-meeting-planner-cont"
        style={{ background: data.color }}
      >
        <div className="md-meeting-planner-wrapper">
          <div className="md-meeting-planner-title">{data.title}</div>
          <div className="md-meeting-planner-time">
            {formatDate("hh:mm A", start)} - {formatDate("hh:mm A", end)}
          </div>
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    if (resources?.length === 0) {
      setEligibleResources([]);
    }

    const data = uniqBy(
      [
        ...fixedResources,
        ...resources?.filter(
          (r) => !fixedResources?.some((fr) => fr.id === r.id)
        ),
        ...invalidInterviewerResources
      ],
      "id"
    )?.sort((a: any, b: any) => {
      if (a.candidate && !b.candidate) return -1;
      if (!a.candidate && b.candidate) return 1;
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    }) as MbscResource[];
    setEligibleResources(data);
  }, [
    resources,
    fixedResourceDetails,
    fixedResources,
    invalidInterviewerResources
  ]);

  useEffect(() => {
    if (fixedResources.length > 0) {
      if (details) {
        const detailsToAdd = {};
        fixedResources.forEach((resource) => {
          if (!fixedResourceDetails[resource.id]) {
            const resourceDetails = details.colors.filter(
              (color) => color.resource === resource.id
            );
            if (resourceDetails.length > 0) {
              detailsToAdd[resource.id] = {
                colors: resourceDetails
              };
            }
          }
        });
        setFixedResourceDetails({
          ...fixedResourceDetails,
          ...detailsToAdd
        });
      }
      if (availabilityData && fixedResources?.some((r) => !r.timezone)) {
        const updatedFixedResources = fixedResources.map((resource) => {
          if (!resource.timezone) {
            const availability = availabilityData.queryWindowsResult.find(
              (x) => x.participant === resource.id
            );
            const timezone =
              availability?.interviewerPreference?.availability?.timezone ??
              "utc";
            return {
              ...resource,
              timezone,
              utcOffset: timezone
            };
          }
          return resource;
        });
        setFixedResources(updatedFixedResources);
      }
    }
  }, [details, fixedResources, availabilityData]);

  useEffect(() => {
    if (selected?.length > 0 && clientDocData) {
      const selectedData = selected?.map((interviewer) => {
        const invalid = !domains?.[getEmailDomain(interviewer.email)]?.provider;
        return {
          id: interviewer.email,
          name: interviewer.name,
          email: interviewer.email,
          organizer: false,
          selected: true,
          worker: interviewer,
          ...(invalid && {
            invalid: true,
            timezone: clientDocData?.workday?.timeFormat ?? "utc",
            utcOffset: clientDocData?.workday?.timeFormat ?? "utc"
          })
        };
      });
      setFixedResources([...selectedData]);
    }
  }, [selected, clientDocData]);

  const myHeader = () => (
    <>
      <CalendarNav />
      <div className="md-meeting-planner-header">
        <div className="md-meeting-planner-zone md-meeting-planner-work">
          {t("ROOSTER.COMMON.NON-WORKING-HOURS", {
            defaultValue: "Non working hours"
          })}
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-flex">
          {t("ROOSTER.COMMON.AVAILABLE", {
            defaultValue: "Available"
          })}
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-off">
          {t("ROOSTER.COMMON.BUSY", {
            defaultValue: "Busy"
          })}
        </div>
        <CalendarPrev />
        {startDateInTimezone.isSameOrBefore(
          moment().tz(interviewTimeZone),
          "day"
        ) && <CalendarToday />}
        <CalendarNext />
      </div>
    </>
  );

  const getEventsForFixedResources = (
    resources: MbscResource[],
    slot: MbscCalendarEvent,
    existingEvent: boolean = false
  ) => {
    return resources?.map((fr) => ({
      start: slot.start,
      end: existingEvent
        ? slot.end
        : moment(slot.start)?.clone().add(eventDuration, "minutes"),
      title: "Selected Slot",
      color: "#1ad404",
      resource: fr.id
    }));
  };

  const handleEventCreateOrUpdate = useCallback(
    (args: MbscEventUpdatedEvent) => {
      //Check if event start is in the past
      if (moment(args.event.start).isBefore(moment())) {
        setToastMessage(
          t("ROOSTER.COMMON.EVENT-START-IN-PAST", {
            defaultValue: "Event cannot be created in the past"
          })
        );
        setToastOpen(true);
        return false;
      }
      const resource = args?.event?.resource;
      if (
        !fixedResources?.some((r) => r.id === resource) ||
        (fixedResources?.length === 1 && fixedResources[0].candidate)
      ) {
        setToastMessage(
          t("ROOSTER.COMMON.SELECT-INTERVIEWER-TO-PROCEED", {
            defaultValue:
              "A slot can be selected when at least one interviewer is added."
          })
        );
        setToastOpen(true);
        return false;
      }
      const events = getEventsForFixedResources(
        fixedResources,
        args?.event,
        !!args?.oldEvent
      );
      setSelectedEvents(events);
      return false;
    },
    [fixedResources]
  );

  const handleEventDeleted = useCallback(() => {
    setSelectedEvents([]);
  }, [selectedEvents]);

  const isSameEvent = (
    event1: MbscCalendarEvent,
    event2: MbscCalendarEvent
  ) => {
    if (!event1 || !event2) return false;
    return (
      moment(event1.start).valueOf() === moment(event2.start)?.valueOf() &&
      moment(event1.end).valueOf() === moment(event2.end)?.valueOf()
    );
  };
  const commonAvailableSlots: MbscCalendarColor[] = useMemo(() => {
    let availableSlots: MbscCalendarColor[] | null = null;
    fixedResources
      ?.filter(
        (fr) =>
          interviewerFilterParams?.considerCandidateAvailability ||
          !fr.candidate
      )
      ?.forEach((resource) => {
        const resourceDetails = fixedResourceDetails[resource.id];
        if (resourceDetails) {
          const resourceColors = resourceDetails?.colors?.filter(
            (color) => !!color.available
          );
          if (availableSlots === null) {
            availableSlots = [...resourceColors];
          } else {
            availableSlots = availableSlots?.filter((slot) => {
              return resourceColors?.some((color) => {
                return isSameEvent(color, slot);
              });
            });
          }
        }
      });
    return availableSlots ?? [];
  }, [
    fixedResources,
    fixedResourceDetails,
    interviewerFilterParams?.considerCandidateAvailability
  ]);

  const handleCloseToast = useCallback(() => setToastOpen(false), []);

  const toggleComparison = useCallback(
    (resource: MbscResource) => {
      const isFixed = resource.selected;
      const origResource = eligibleResources?.find(
        (r) => r.id === resource.id
      )!;
      let newFixedResources: any = [];
      let newFixedNr = fixedNr;

      if (!isFixed) {
        origResource!.selected = true;
        newFixedResources = [...fixedResources, origResource];
        newFixedNr++;
      } else {
        origResource!.selected = false;
        newFixedResources = fixedResources.filter((r) => r.id !== resource.id);
        newFixedNr--;
      }

      const newResources = [...newFixedResources];
      eligibleResources?.forEach((r) => {
        if (!r.selected) {
          newResources.push(r);
        }
      });

      setEligibleResources(newResources);
      setFixedResources(newFixedResources);
      setFixedNr(newFixedNr);
      setSelectedEvents([]);
    },
    [fixedNr, fixedResources, eligibleResources]
  );
  const handlePageChange = (_, value: number) => {
    setPage(value);
  };
  const customResource = useCallback(
    (r: MbscResource) => (
      <Stack direction={"row"} sx={{ display: "flex" }}>
        <Box
          sx={{
            flexGrow: 1
          }}
        >
          <Tooltip title={r.name}>
            <Typography noWrap sx={{ maxWidth: 150 }}>
              {r.name}
            </Typography>
          </Tooltip>
          <Stack direction={"row"}>
            {r.invalid && (
              <Typography
                color={"error"}
                variant="subtitle2"
                sx={{ fontSize: 12 }}
              >
                <Tooltip
                  title={t("ROOSTER.COMMON.EMAIL-PROVIDER-NOT-CONFIGURED", {
                    defaultValue:
                      "Unable to fetch availability details. Most likely because of invalid email or not part of valid domain."
                  })}
                >
                  <ErrorOutlineIcon color="error" />
                </Tooltip>
              </Typography>
            )}
            {r.utcOffset && (
              <Tooltip
                title={
                  r.utcOffset + "- " + moment().tz(r.timezone).format("zZ")
                }
              >
                <Typography
                  noWrap
                  variant="subtitle1"
                  sx={{ fontSize: 12, maxWidth: 150 }}
                >
                  {r.utcOffset}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        </Box>
        {!r.candidate &&
          r?.email &&
          (!!domains?.[getEmailDomain(r?.email)]?.provider || r.selected) && (
            <IconButton
              size="small"
              sx={{ width: 30 }}
              color={r.selected ? "error" : "success"}
              onClick={() => toggleComparison(r)}
            >
              {r.selected ? (
                <RemoveCircleOutlineRoundedIcon />
              ) : (
                <AddCircleOutlineRoundedIcon />
              )}
            </IconButton>
          )}
        {r.candidate && <AccountCircleOutlinedIcon color="primary" />}
      </Stack>
    ),
    [fixedNr, toggleComparison]
  );

  const debouncedRefetch = useCallback(
    debounce(() => {
      interviewersRefetch();
    }, 800),
    [interviewersRefetch]
  );
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setInterviewerFilterParams({
      ...interviewerFilterParams,
      searchTerm,
      page: 1
    });
  };

  const suggestTimeListItemText = (
    fixedResources: MbscResource[],
    slot: MbscCalendarEvent
  ) => {
    return (
      <ListItemText
        primary={
          <Typography component="span" variant="subtitle1" color="primary">
            {moment(slot.start).clone().tz(interviewTimeZone).format("lll z")}
          </Typography>
        }
        secondary={
          <>
            {fixedResources?.map((resource, index) => (
              <Stack key={index} direction={"row"} spacing={0.5} pt={0.5}>
                <Avatar sx={{ width: 18, height: 18 }} alt={resource.name}>
                  {resource.name?.charAt(0)}
                </Avatar>
                <Typography
                  color={resource.candidate ? "primary" : "secondary"}
                  variant="subtitle2"
                  sx={{ fontSize: 12, width: 120 }}
                >
                  {resource.name}
                </Typography>

                {resource.timezone && (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ fontSize: 12 }}
                  >
                    {moment(slot.start)
                      ?.clone()
                      ?.tz(resource.timezone)
                      ?.format("LT z")}{" "}
                    -{" "}
                    {moment(slot.end)
                      ?.clone()
                      ?.tz(resource.timezone)
                      ?.format("LT z")}
                  </Typography>
                )}
              </Stack>
            ))}
          </>
        }
      />
    );
  };

  const selectedDate = useMemo(() => {
    if (selectedEvents?.length > 0) {
      document
        .getElementById("interviewers-selector-suggested-times")
        ?.scrollTo({ top: 0, behavior: "smooth" });
    }
    return (
      selectedEvents?.length > 0
        ? moment(selectedEvents?.[0].start).tz(interviewTimeZone)
        : startDateInTimezone
    ).toDate();
  }, [selectedEvents]);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mx={1}>
        <TextField
          sx={{ m: 0, minWidth: 250 }}
          label={t("ROOSTER.COMMON.SEARCH_MIN_3", {
            defaultValue: "Search (type at least 3 chars)"
          })}
          variant="outlined"
          size={"small"}
          margin="normal"
          value={interviewerFilterParams?.searchTerm}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{
                  visibility: interviewerFilterParams?.searchTerm
                    ? "visible"
                    : "hidden"
                }}
                onClick={() => {
                  setInterviewerFilterParams({
                    ...interviewerFilterParams,
                    searchTerm: "",
                    page: 1
                  });
                }}
              >
                <ClearIcon sx={{ fontSize: 13 }} />
              </IconButton>
            )
          }}
          onChange={handleSearch}
        />
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={
                  !(availabilityResponse?.data?.availability?.length > 0)
                }
                checked={interviewerFilterParams?.considerCandidateAvailability}
                onChange={(e) => {
                  setInterviewerFilterParams({
                    ...interviewerFilterParams,
                    considerCandidateAvailability: e.target.checked
                  });
                }}
                name="prioritizeCandidate"
                color="primary"
              />
            }
            label={t("ROOSTER.COMMON.Consider-CANDIDATE-AVAILABILITY", {
              defaultValue: "Consider Candidate Availability"
            })}
          />
          <FormControl sx={{ minWidth: 200 }}>
            <Autocomplete
              multiple
              sx={{ minWidth: 300, maxWidth: 300 }}
              options={moment.tz.names()}
              value={timezones ?? []}
              size="small"
              onChange={(_, values: string[]) => {
                setTimezones(values);
                setPage(1);
                debouncedRefetch();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("ROOSTER.COMMON.FILTER-BY-TIMEZONE", {
                    defaultValue: "Filter by Timezones"
                  })}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <TagsSelect
              fetchArchived={true}
              value={interviewerFilterParams?.selectedTags ?? []}
              onChange={(value: Tag[]) => {
                setInterviewerFilterParams({
                  ...interviewerFilterParams,
                  tags: value.map((tag) => tag.id),
                  page: 1,
                  selectedTags: value
                });
                debouncedRefetch();
              }}
            />
          </FormControl>
          <Autocomplete
            multiple
            sx={{ minWidth: 300, maxWidth: 300 }}
            options={
              departmentsResponse?.data?.departments?.sort((a, b) =>
                a.localeCompare(b)
              ) ?? []
            }
            value={interviewerFilterParams?.departments ?? []}
            size="small"
            onChange={(_, values: string[]) => {
              setInterviewerFilterParams({
                ...interviewerFilterParams,
                departments: values,
                page: 1
              });
              debouncedRefetch();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("ROOSTER.COMMON.FILTER-BY-DEPARTMENTS", {
                  defaultValue: "Filter by Departments"
                })}
                variant="outlined"
              />
            )}
          />
        </Stack>
      </Stack>
      {(availabilityLoading || interviewersLoading) && (
        <>
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton key={index} height={60} animation="wave" />
          ))}
        </>
      )}
      {!availabilityLoading &&
        !interviewersLoading &&
        eligibleResources?.filter((r) => !r.candidate)?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70%"
            }}
          >
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{ mt: 2 }}
            >
              {t("ROOSTER.COMMON.NO-INTERVIEWERS-FOUND", {
                defaultValue:
                  "No interviewers are available for the specified criteria."
              })}
            </Typography>
          </Box>
        )}
      <Box
        sx={{
          display: "flex",
          mx: 0.5
        }}
      >
        <Box
          sx={{
            height: "calc(100vh - 160px)",
            flexGrow: 1,
            b: 0.5,
            display:
              availabilityLoading ||
              interviewersLoading ||
              eligibleResources?.filter((r) => !r.candidate)?.length === 0
                ? "none"
                : "block",
            width:
              commonAvailableSlots?.length > 0 || selectedEvents?.length > 0
                ? "calc(100vw - 350px)"
                : "100%"
          }}
        >
          <Eventcalendar
            timezonePlugin={momentTimezone}
            dataTimezone={interviewTimeZone}
            displayTimezone={interviewTimeZone}
            clickToCreate="double"
            dragToCreate
            dragToMove
            dragToResize
            dragTimeStep={15}
            view={myView}
            data={selectedEvents}
            refDate={selectedDate}
            resources={eligibleResources}
            colors={[...fixedResourcesColors, ...details.colors]}
            invalid={[]}
            renderScheduleEvent={myScheduleEvent}
            renderHeader={myHeader}
            renderResource={customResource}
            onEventClick={handleEventDeleted}
            onEventCreate={handleEventCreateOrUpdate}
            onEventUpdated={handleEventCreateOrUpdate}
            onEventDeleted={handleEventDeleted}
            min={startDateInTimezone.toDate()}
            max={endTimeInTimezone.toDate()}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Pagination
              count={Math.ceil(
                (interviewersResponse?.interviewers?.count ?? 0) / 12
              )}
              size="small"
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
        {((commonAvailableSlots?.length > 0 &&
          fixedResources?.some((fr) => !fr.candidate)) ||
          selectedEvents?.length > 0) && (
          <Box
            id="interviewers-selector-suggested-times"
            sx={{
              height: "calc(100vh - 160px)",
              overflow: "auto",
              display:
                availabilityLoading || interviewersLoading ? "none" : "block",
              ml: 1
            }}
          >
            {selectedEvents?.length > 0 && (
              <>
                <Typography variant="h6" color={"primary"} sx={{ mt: 1 }}>
                  {t("ROOSTER.COMMON.SELECTED-SLOT", {
                    defaultValue: "Selected Slot"
                  })}
                </Typography>
                <Divider />
                <ListItemButton
                  sx={{
                    px: 0.25,
                    py: 2,
                    background: "#c9e8d1"
                  }}
                  key={"selected-event"}
                  alignItems="flex-start"
                >
                  {suggestTimeListItemText(fixedResources, selectedEvents?.[0])}
                </ListItemButton>
                <Divider />
              </>
            )}
            {commonAvailableSlots?.length > 0 && (
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h6" color={"primary"} sx={{ pt: 0.5 }}>
                  {t("ROOSTER.COMMON.SUGGESTED-TIMES", {
                    defaultValue: "Suggested Times"
                  })}
                </Typography>
                <Tooltip
                  title={t("ROOSTER.COMMON.SUGGESTED-TIMES-INFO", {
                    defaultValue:
                      "If Consider Candidate Availability is ON then the suggested times will prioritize interview times based on the candidate’s provided availability. If this option is toggled OFF then the suggested times will not consider the candidate’s provided availability, but will list the candidate as they are an attendee for the interview."
                  })}
                >
                  <IconButton size="small" sx={{ width: 30 }}>
                    <InfoOutlinedIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}

            <List
              sx={{
                width: "100%",
                maxWidth: 350,
                bgcolor: "background.paper",
                p: 0
              }}
            >
              <Divider />

              {commonAvailableSlots?.map((slot, index) => (
                <>
                  {!isSameEvent(slot, selectedEvents?.[0]) && (
                    <>
                      <ListItemButton
                        sx={{
                          px: 0.25,
                          py: 2
                        }}
                        key={index}
                        onClick={() => {
                          setSelectedEvents(
                            fixedResources?.map((fr) => ({
                              start: slot.start,
                              end: slot.end,
                              title: "Selected Slot",
                              color: "#1ad404",
                              resource: fr.id
                            }))
                          );
                        }}
                        alignItems="flex-start"
                      >
                        {suggestTimeListItemText(fixedResources, slot)}
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                </>
              ))}
            </List>
          </Box>
        )}
      </Box>

      <Toast
        message={toastMessage}
        isOpen={isToastOpen}
        onClose={handleCloseToast}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={1}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => {
            setSelectedEvents([]);
            if (onCancel) {
              onCancel();
            }
          }}
        >
          {t("ROOSTER.COMMON.CANCEL", {
            defaultValue: "Cancel"
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={selectedEvents?.length === 0}
          onClick={() => {
            if (onApply) {
              const startOfDayInTz = moment(selectedEvents?.[0].start)
                .tz(interviewTimeZone)
                .startOf("day");
              const startTimeInMinutes = moment(selectedEvents?.[0].start)
                .tz(interviewTimeZone)
                .diff(startOfDayInTz, "minutes");
              const durationMinutes = moment(selectedEvents?.[0].end).diff(
                moment(selectedEvents?.[0].start),
                "minutes"
              );
              onApply(
                {
                  startDate: moment(selectedEvents?.[0].start)
                    .clone()
                    .tz(interviewTimeZone)
                    .startOf("day")
                    .toDate(),
                  startTimeInMins: startTimeInMinutes,
                  duration: durationMinutes
                },
                fixedResources
                  ?.filter((fr) => !fr.candidate)
                  ?.map((fr) => ({
                    ...fr.worker,
                    wid: fr.worker?.id
                  }))
              );
            }
          }}
        >
          {t("ROOSTER.COMMON.APPLY", {
            defaultValue: "Apply"
          })}
        </Button>
      </Stack>
    </>
  );
};
export default InterviewersAvailability;

interface MyDate extends Date {
  clone(): MyDate;
  setTimezone(timezone: MyDate): void;
}
