import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cs from "classnames";
import React from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { DialogContent, Dialog, Button, Typography } from "@mui/material";

import { ButtonList, ButtonOptions } from "../../hooks/useRoosterAlert";
import { alertPopupStateType, hide } from "../../reducers/slices/alertPopup";
import { primaryColor, roosterRed } from "../@rooster/UIConstants";
import { Wrapper } from "./styled";

export const AlertPopup = () => {
  const alertPopupState = useSelector(
    (state: any) => state.alertPopupReducer
  ) as alertPopupStateType;
  const { show, title, text, callback, buttons, customComponent, dangerMode } =
    alertPopupState;
  const dispatch = useDispatch();
  const onHide = () => {
    dispatch(hide());
    if (callback) {
      callback(false);
    }
  };
  const visibleBtnLength = Object.keys(buttons).filter(
    a => ((buttons as ButtonList)[a] as ButtonOptions).visible
  ).length;
  const cancelButton = (buttons as ButtonList).cancel as ButtonOptions;
  const confirmButton = (buttons as ButtonList).confirm as ButtonOptions;
  const onConfirm = () => {
    dispatch(hide());
    if (callback) {
      callback(confirmButton?.value);
    }
  };

  return (
    <Dialog maxWidth="md" open={show} onClose={onHide}>
      <DialogContent>
        <div>
          <Alert
            variant={dangerMode ? "danger" : "secondary"}
            style={{ textAlign: "center", color: primaryColor }}
          >
            <Typography variant="h5">
              {dangerMode && (
                <FontAwesomeIcon
                  className="mr-2"
                  color={roosterRed}
                  icon={["fal", "exclamation-circle"]}
                />
              )}
              <Typography component="b">{title || null}</Typography>
            </Typography>
          </Alert>
        </div>
        <Wrapper className="container">
          <Typography variant="h5" className="text-center">
            {text}
          </Typography>
          <div>{customComponent || null}</div>
          <div
            className={cs(
              "mt-4 row d-flex",
              visibleBtnLength >= 2
                ? "justify-content-between"
                : "justify-content-center"
            )}
          >
            <Button
              style={{ display: !cancelButton?.visible ? "none" : "block" }}
              className={cs(cancelButton?.className)}
              onClick={onHide}
              variant="outlined"
            >
              <FontAwesomeIcon icon="angle-double-left" className="mr-2" />
              <Typography component="b">{cancelButton?.text}</Typography>
            </Button>
            <Button
              style={{ display: !confirmButton?.visible ? "none" : "block" }}
              className={cs(confirmButton?.className)}
              onClick={onConfirm}
              variant="outlined"
            >
              <FontAwesomeIcon icon="check-circle" className="mr-2" />
              <Typography component="b">{confirmButton?.text}</Typography>
            </Button>
          </div>
        </Wrapper>
      </DialogContent>
    </Dialog>
  );
};
