import { partition, uniqBy } from "lodash";
import {
  DEFAULT_GOOGLE_BUILDING,
  DEFAULT_OFFICE365_BUILDING
} from "../constants";

const INITIAL_STATE = {
  roles: undefined,
  conferencing: undefined,
  skills: undefined
};

const applySetRoles = (state, action) => ({
  ...state,
  roles: action.roles
});

const applySetConferencing = (state, action) => ({
  ...state,
  conferencing: action.conferencing
});

const applySetConferencingRooms = (state, action) => {
  const existingRooms = state.conferencing ?? [];
  const newRooms = action.rooms;

  const [existingRoomsWithBuildingInfo, existingRoomsWithDefaultBuilding] =
    partition(
      existingRooms,
      room =>
        ![DEFAULT_GOOGLE_BUILDING, DEFAULT_OFFICE365_BUILDING].includes(
          room.buildingId
        )
    );

  return {
    ...state,
    conferencing: uniqBy(
      [
        ...existingRoomsWithBuildingInfo,
        ...newRooms,
        ...existingRoomsWithDefaultBuilding
      ],
      "value"
    )
  };
};

const applySetSkills = (state, action) => ({
  ...state,
  skills: action.skills
});

export const ROLES_SET_ACTION = "ROLES_SET";
export const CONFERENCING_SET_ACTION = "CONFERENCING_SET";
export const CONFERENCING_ROOMS_SET_ACTION = "CONFERENCING_ROOMS_SET";
export const SKILLS_SET_ACTION = "SKILLS_SET";

const workdayDataReducer = (state, action) => {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case ROLES_SET_ACTION: {
      return applySetRoles(state, action);
    }
    case CONFERENCING_SET_ACTION: {
      return applySetConferencing(state, action);
    }
    case SKILLS_SET_ACTION: {
      return applySetSkills(state, action);
    }
    case CONFERENCING_ROOMS_SET_ACTION: {
      return applySetConferencingRooms(state, action);
    }
    default:
      return state;
  }
};

export default workdayDataReducer;
