const INITIAL_STATE = {
  events: null
};

const applySetInterview = (state, action) => ({
  ...state,
  events: action.events
});

function eventsReducer(state, action) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  if (action.type === "EVENTS_SET") {
    return applySetInterview(state, action);
  }
  return state;
}

export default eventsReducer;
