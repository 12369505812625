import { DomainDocReduxType, IDomain } from "@rooster/types";
import { omit } from "lodash";
import { db } from "../../../../src";
import * as TABLES from "../../../constants/databaseTables";

export const getClientDomainDocs = async (
  client: string
): Promise<DomainDocReduxType[]> => {
  const domainsSnapshot = await db
    .collection(TABLES.DOMAINS)
    .where("client", "==", client)
    .where("enabled", "==", true)
    .get();

  const domainDocs = domainsSnapshot.docs;
  return domainDocs
    .map(doc => ({ id: doc.id, ...(doc.data() as IDomain) }))
    ?.map(data => omit(data, ["token", "credentials"]));
};
