import React from "react";

export default function TWrapper({ id, value, isEditMode, count }) {
  return (
    <span
      id={id}
      data-count={count}
      style={isEditMode ? { border: "red 1px dotted" } : {}}
      onContextMenu={e => {
        e.preventDefault();
      }}
    >
      {value}
    </span>
  );
}
