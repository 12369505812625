const INITIAL_STATE = {
  zoomPMI: "",
  webex: {
    domain: ""
  },
  clientSettings: {},
  sms: {},
  loadCachedOffice365Rooms: false
};

const applyZoomPMI = (state, action) => ({
  ...state,
  zoomPMI: action.pmi
});

const applyWebexDomain = (state, action) => ({
  ...state,
  webex: {
    ...state.webex,
    domain: action.domain
  }
});

const applyClientSettings = (state, action) => ({
  ...state,
  clientSettings: action.clientSettings
});

const applySmsSettings = (state, action) => ({
  ...state,
  sms: {
    ...action.smsSettings
  }
});

const applyLoadCached = (state, action) => ({
  ...state,
  loadCachedOffice365Rooms: action.loadCachedOffice365Rooms
});

export const SET_ZOOM_PMI_ACTION = "SET_ZOOM_PMI";
export const SET_WEBEX_DOMAIN_ACTION = "SET_WEBEX_DOMAIN";
export const SET_CLIENT_SETTINGS_ACTION = "SET_CLIENT_SETTINGS";
export const SET_SMS_SETTINGS_ACTION = "SET_SMS_SETTINGS";
export const SET_LOAD_CACHED_O365_ROOMS_ACTION = "SET_LOAD_CACHED_O365_ROOMS";

function miscellaneousSettingsReducer(state, action) {
  if (typeof state === "undefined") {
    state = INITIAL_STATE;
  }
  if (action.type === SET_ZOOM_PMI_ACTION) {
    return applyZoomPMI(state, action);
  }
  if (action.type === SET_WEBEX_DOMAIN_ACTION) {
    return applyWebexDomain(state, action);
  }
  if (action.type === SET_CLIENT_SETTINGS_ACTION) {
    return applyClientSettings(state, action);
  }
  if (action.type === SET_SMS_SETTINGS_ACTION) {
    return applySmsSettings(state, action);
  }
  if (action.type === SET_LOAD_CACHED_O365_ROOMS_ACTION) {
    return applyLoadCached(state, action);
  }
  return state;
}

export default miscellaneousSettingsReducer;
