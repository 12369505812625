import { IInterviewerPreferences } from "../components/InterviewerPreferences/types";
import { GCF } from "../constants/dbQuery";
import { get } from "./restClient";

export const INTERVIEWER_PREFERENCE_INTERNAL_ENDPOINT = `${GCF.BASE_URL}/interviewerPreferences/preference`;
const INTERVIEWER_PREFERENCE_INTERNAL_ENDPOINT_TIMEOUT_MS = 60000;

export const fetchInterviewerPreferences = async (
  id: string,
  timezone: string | null,
  skipSync?: boolean
): Promise<{
  id: string;
  preferences: IInterviewerPreferences | undefined;
}> => {
  try {
    const { data } = await get(
      `${INTERVIEWER_PREFERENCE_INTERNAL_ENDPOINT}/${id}`,
      {
        params: {
          timezone,
          skipSync
        }
      },
      INTERVIEWER_PREFERENCE_INTERNAL_ENDPOINT_TIMEOUT_MS
    );
    return { id, preferences: data?.preferences };
  } catch (e) {
    return { id, preferences: undefined };
  }
};
