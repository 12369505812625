/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IInterviewerPreference } from "../../contexts/interviewer/interviewerPreferences";
import { fetchInterviewerPreferences } from "../../utilities/fetchInterviewerPreferences";
import { IRoosterRootState } from "../rootStateType";

export const getInterviewerPreferences = createAsyncThunk<any, any>(
  "interviewersPreferences/getPreferences",
  async (payload, { getState }) => {
    const state = getState() as IRoosterRootState;
    const { interviewers } = state.interviewersPreferences;
    if (
      !interviewers.find(
        interviewer => interviewer.id === payload.interviewerEmail
      )
    ) {
      return await fetchInterviewerPreferences(
        payload.interviewerEmail,
        payload.timezone
      );
    }

    return null;
  }
);

export interface IInitialInterviewerPreferenceState {
  interviewers: IInterviewerPreference[];
}
const initialState: IInitialInterviewerPreferenceState = {
  interviewers: []
};

export const interviewersPreferences = createSlice({
  name: "interviewersPreferences",
  initialState,
  reducers: {
    setInterviewerPreferences(
      state,
      action: PayloadAction<IInterviewerPreference[]>
    ) {
      state.interviewers = action.payload;
    },
    clearInterviewerPreferences(state) {
      state.interviewers = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(getInterviewerPreferences.fulfilled, (state, action) => {
      if (action.payload) {
        state.interviewers.push(action.payload);
      }
    });
  }
});

export const { setInterviewerPreferences, clearInterviewerPreferences } =
  interviewersPreferences.actions;

export type InterviewersPreferences = typeof initialState;

export default interviewersPreferences.reducer;
