const InterviewIcons = (iconType, name) => {
  let returnIcon = null;
  switch (iconType) {
    case "conferencing": {
      switch (name) {
        case "In Person": {
          returnIcon = "user";
          break;
        }
        case "Phone": {
          returnIcon = "phone-square-alt";
          break;
        }
        case "Video": {
          returnIcon = "webcam";
          break;
        }
        default: {
          returnIcon = "user";
        }
      }
      break;
    }
    case "room": {
      returnIcon = "door-open";
      break;
    }
    case "zoom": {
      returnIcon = "webcam";
      break;
    }
    case "webex": {
      returnIcon = "webcam";
      break;
    }
    case "eightbyeight": {
      returnIcon = "webcam";
      break;
    }
    case "msteams": {
      returnIcon = "webcam";
      break;
    }
    case "google": {
      returnIcon = "webcam";
      break;
    }
    case "competency": {
      returnIcon = "file-user";
      break;
    }
    case "questionnaire": {
      returnIcon = "tasks";
      break;
    }
    case "location": {
      returnIcon = "map-marker-alt";
      break;
    }
    case "comment": {
      returnIcon = "comment-alt-lines";
      break;
    }
    default: {
      returnIcon = "user";
    }
  }
  return returnIcon;
};

export default InterviewIcons;
