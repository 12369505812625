import { gql } from "../../../@generated/gql";

export const GET_INTERVIEWERS = gql(/* GraphQL */ `
  query GetInterviewers($filter: InterviewersFilterDto!) {
    interviewers(filter: $filter) {
      count
      interviewers {
        id
        clientId
        countryCode
        email
        name
        type
        title
        key
        firstName
        lastName
        value
        department
        tags {
          id
          name
          clientId
          type
          archived
        }
        preferences {
          availability {
            timezone
          }
        }
      }
    }
  }
`);

export const UPDATE_INTERVIEWER = gql(/* GraphQL */ `
  mutation UpdateInterviewer($id: String!, $data: InterviewerUpdateInput!) {
    updateInterviewer(id: $id, data: $data) {
      id
    }
  }
`);

export const UPDATE_MANY_INTERVIEWERS = gql(/* GraphQL */ `
  mutation UpdateManyInterviewers(
    $where: InterviewerWhereInput!
    $data: InterviewerUpdateInput!
  ) {
    updateManyInterviewers(where: $where, data: $data) {
      count
    }
  }
`);
