/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InterviewDocAutomationSchema } from "@rooster/types";

interface IInitialState {
  rules: InterviewDocAutomationSchema | null;
  finalInterviewDocRules: InterviewDocAutomationSchema | null;
}
const initialState: IInitialState = {
  rules: {},
  finalInterviewDocRules: {}
};

export const automationSlice = createSlice({
  name: "automationRules",
  initialState,
  reducers: {
    updateRule(state, action: PayloadAction<InterviewDocAutomationSchema>) {
      state.rules = action.payload;
    },
    updateFinalInterviewDocRules(
      state,
      action: PayloadAction<InterviewDocAutomationSchema>
    ) {
      state.finalInterviewDocRules = action.payload;
    }
  }
});

export const { updateRule, updateFinalInterviewDocRules } =
  automationSlice.actions;

export type AutomationStateType = typeof initialState;

export default automationSlice.reducer;
