import React from "react";
import { css } from "@emotion/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Instructions = ({ customerId }) => {
  return (
    <span>
      {customerId && (
        <div
          className={`${css`
            min-height: 240px;
          `} d-flex flex-column justify-content-center align-items-center`}
        >
          <FontAwesomeIcon
            className={`${css`
              color: #25aae1;
            `} mb-3`}
            icon="edit"
            size="3x"
            fixedWidth
          />
          Right click on an element with a red border to start editing the
          translation
        </div>
      )}
      {!customerId && (
        <div
          className={`${css`
            min-height: 240px;
          `} d-flex flex-column justify-content-center align-items-center`}
        >
          <FontAwesomeIcon
            className={`${css`
              color: #25aae1;
            `} mb-3`}
            icon="globe"
            size="3x"
            fixedWidth
          />
          Enable I18n to use In-context Editor
        </div>
      )}
    </span>
  );
};

export default Instructions;
