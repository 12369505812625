const INITIAL_STATE = {
  jobApplications: [],
  jobApplicationsDashboard: [],
  jobApplicationsPagination: {},
  jobApplicationsInitialLoadTime: new Date(),
  coordinators: [],
  initiators: []
};

const applySetJobApplications = (state, action) => ({
  ...state,
  jobApplications: action.jobApplications
});

const applySetJobApplicationsDashboard = (state, action) => ({
  ...state,
  jobApplicationsDashboard: action.jobApplicationsDashboard
});

const applySetJobApplicationsPagination = (state, action) => ({
  ...state,
  jobApplicationsPagination: action.jobApplicationsPagination
});

const applySetCoordinators = (state, action) => ({
  ...state,
  coordinators: action.coordinators
});

const applySetInitiators = (state, action) => ({
  ...state,
  initiators: action.initiators
});

const applySetJobApplicationsInitialLoadTime = (state, action) => ({
  ...state,
  jobApplicationsInitialLoadTime: action.jobApplicationsInitialLoadTime
});

const resetJobApplicationDashboard = state => ({
  ...state,
  jobApplicationsDashboard: []
});

export const JOB_APPLICATIONS_SET = "JOB_APPLICATIONS_SET";
export const JOB_APPLICATIONS_DASHBOARD_SET = "JOB_APPLICATIONS_DASHBOARD_SET";
export const JOB_APPLICATIONS_INITIAL_LOAD_TIME_SET =
  "JOB_APPLICATIONS_INITIAL_LOAD_TIME_SET";
export const JOB_APPLICATIONS_PAGINATION_SET =
  "JOB_APPLICATIONS_PAGINATION_SET";
export const COORDINATORS_SET = "COORDINATORS_SET";
export const INITIATORS_SET = "INITIATORS_SET";
export const JOB_APPLICATIONS_DASHBOARD_RESET =
  "JOB_APPLICATIONS_DASHBOARD_RESET";

function clientDashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JOB_APPLICATIONS_SET:
      return applySetJobApplications(state, action);
    case JOB_APPLICATIONS_DASHBOARD_SET:
      return applySetJobApplicationsDashboard(state, action);
    case JOB_APPLICATIONS_PAGINATION_SET:
      return applySetJobApplicationsPagination(state, action);
    case JOB_APPLICATIONS_INITIAL_LOAD_TIME_SET:
      return applySetJobApplicationsInitialLoadTime(state, action);
    case COORDINATORS_SET:
      return applySetCoordinators(state, action);
    case INITIATORS_SET:
      return applySetInitiators(state, action);
    case JOB_APPLICATIONS_DASHBOARD_RESET:
      return resetJobApplicationDashboard(state);

    default:
      return state;
  }
}

export default clientDashboardReducer;
